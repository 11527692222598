import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/NavbarMaterial';
import Footer from './components/Footer';
import PrivacyTermsPage from './pages/PrivacyTermsPage';
import FormPage from './pages/FormPage';
import LandingPage from './pages/LandingPage';
import DeskPage from './pages/DeskPage';
import DeskPagePrivate from './pages/DeskPage-private';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import AboutUs from './pages/AboutUs';
import EpicManager from './components/epics/EpicManager';
import { LanguageProvider } from './context/LanguageContext';
import { ProductProvider } from './context/ProductContext';
import { AuthProvider } from './context/AuthContext'; // Importa el AuthProvider
import PrivateRoute from './components/PrivateRoute';
import EmailVerification from './pages/EmailVerification';

function App() {
    return (
        <LanguageProvider>
            <AuthProvider> {/* Envuelve tu aplicación con AuthProvider */}
                <Router>
                    <Navbar />
                    <Routes>
                        <Route path="/" element={<LandingPage />} />
                        <Route path="/automate-user-stories" element={<DeskPage />} />
                        <Route path="/DeskPagePrivate" element={
                            <ProductProvider>
                                <PrivateRoute>
                                    <DeskPagePrivate />
                                </PrivateRoute>
                            </ProductProvider>
                        } />
                        <Route path="/privacy-terms" element={<PrivacyTermsPage />} />
                        <Route path="/stories-generate-page" element={<FormPage />} />
                        <Route path="/SignIn" element={<SignIn />} />
                        <Route path="/SignUp" element={<SignUp />} />
                        <Route path="/ForgotPassword" element={<ForgotPassword />} />
                        <Route path="/reset-password" element={<ResetPassword />} />
                        <Route path="/about-us" element={<AboutUs />} />
                        <Route path="/EpicManager" element={<EpicManager />} />
                        <Route path="/verify-email" element={<EmailVerification />} /> {/* Ruta de verificación de email */}
                    </Routes>
                    <Footer />
                </Router>
            </AuthProvider>
        </LanguageProvider>
    );
}

export default App;
