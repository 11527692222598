// EmailVerification.js
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';

const EmailVerification = () => {
    const [searchParams] = useSearchParams();
    const [message, setMessage] = useState('Verifying...');

    useEffect(() => {
        const token = searchParams.get('token');
        if (token) {
            const url = process.env.NODE_ENV === 'development'
                ? `http://localhost:3001/api/verify-email?token=${token}`
                : `/api/verify-email?token=${token}`;

            axios.get(url)
                .then(response => {
                    setMessage(response.data.message);
                })
                .catch(error => {
                    setMessage('Error verifying email');
                });
        }
    }, [searchParams]);

    return (
        <div>
            <h2>{message}</h2>
        </div>
    );
};

export default EmailVerification;
