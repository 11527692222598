import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import EpicCard from './EpicCard';
import '../css/customMaterial.css';

function EpicForm() {
    const [context, setContext] = useState('');
    const [isGeneratingEpic, setIsGeneratingEpic] = useState(false);
    const [epics, setEpics] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    const { t } = useTranslation();

    const handleGenerateEpics = async () => {
        setIsGeneratingEpic(true);
        const prompt = t('epicsPrompt', { context });
        try {
            const data = await sendPromptToServer(prompt);
            setEpics(data.generatedText.split('\n\n').map(epic => epic.trim()).filter(epic => epic.length > 0));
        } catch (error) {
            console.error("Error generating epics:", error);
            setErrorMessage(`${t('errorGeneratingEpics')} ${error.message}`);
        } finally {
            setIsGeneratingEpic(false);
        }
    };

    async function sendPromptToServer(prompt) {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/generate-text`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ prompt }),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Container component="main" maxWidth="md" sx={{ mt: 4 }}>
                <Box className="content-section">
                    <Typography variant="h6" className="section-title">
                        {t('generateEpicsTitle')}
                    </Typography>
                    <Typography variant="body1" className="section-description">
                        {t('generateEpicsDesc')}
                    </Typography>
                </Box>
                <Box component="form" noValidate sx={{ mt: 1 }}>
                    <Typography variant="caption" className="form-caption caption-text" sx={{ display: 'block', mb: 1, color: 'text.secondary' }}>
                        {t('contextLabel')}
                    </Typography>
                    <TextField
                        required
                        fullWidth
                        id="context"
                        label={''}
                        autoComplete="context"
                        autoFocus
                        multiline
                        rows={4}
                        value={context}
                        onChange={(e) => setContext(e.target.value)}
                        variant="outlined"
                    />
                    <Button
                        className="custom-button"
                        type="button"
                        disabled={isGeneratingEpic}
                        onClick={handleGenerateEpics}
                    >
                        {isGeneratingEpic ? <CircularProgress size={24} /> : t('generateEpicsButton')}
                    </Button>
                </Box>
                {errorMessage && <Typography color="error" sx={{ mt: 2 }}>{errorMessage}</Typography>}
                {
                    epics.map((epic, index) => (
                        <EpicCard key={index} epic={epic} />
                    ))
                }
            </Container >
        </Box >
    );
}

export default EpicForm;
