import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, MenuItem, TextField } from '@mui/material';
import { useProduct } from '../context/ProductContext';

const ProductSelectorWrapper = ({ isDrawerOpen }) => {
    const { t } = useTranslation();
    const { selectedProduct, setSelectedProduct, products, fetchProducts } = useProduct();

    useEffect(() => {
        fetchProducts();
    }, [fetchProducts]);

    const filteredProducts = products.filter(
        product => product.status === 'active' || product.status === 'in_development'
    );

    return (
        <Box sx={{ margin: '16px', display: isDrawerOpen ? 'block' : 'none' }}>
            <TextField
                select
                label={t('selectProductLabel')}
                variant="outlined"
                fullWidth
                value={selectedProduct}
                onChange={(e) => setSelectedProduct(e.target.value)}
                size="small"
            >
                {filteredProducts.map((product) => (
                    <MenuItem key={product.product_id} value={product.product_id}>
                        {product.name}
                    </MenuItem>
                ))}
            </TextField>
        </Box>
    );
};

export default ProductSelectorWrapper;
