import React, { useState, useEffect } from 'react';
import { Container, Box, TextField, Button, Grid, Typography, Paper, CircularProgress, Pagination, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import CustomSnackbar from '../messages/CustomSnackbar';
import { useProduct } from '../../context/ProductContext';

const EditCriteria = () => {
    const { selectedProduct } = useProduct();
    const [selectedEpic, setSelectedEpic] = useState('');
    const [epics, setEpics] = useState([]);
    const [selectedStory, setSelectedStory] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [stories, setStories] = useState([]);
    const [filteredStories, setFilteredStories] = useState([]);
    const [criteria, setCriteria] = useState(null);
    const [description, setDescription] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarVariant, setSnackbarVariant] = useState('success');
    const { t } = useTranslation();
    const [currentPage, setCurrentPage] = useState(1);
    const storiesPerPage = 6;

    const fetchEpics = async () => {
        if (selectedProduct) {
            try {
                const response = await axios.get(`/api/epics/product/${selectedProduct}`);
                setEpics(response.data);
            } catch (error) {
                console.error('Error fetching epics:', error);
            }
        }
    };

    const fetchStories = async (epicId) => {
        if (epicId) {
            try {
                const response = await axios.get(`/api/stories/epic/${epicId}`);
                setStories(response.data);
                setFilteredStories(response.data);
            } catch (error) {
                console.error('Error fetching stories:', error);
            }
        }
    };

    useEffect(() => {
        fetchEpics();
    }, [selectedProduct]);

    useEffect(() => {
        fetchStories(selectedEpic);
    }, [selectedEpic]);

    useEffect(() => {
        const filtered = stories.filter(story =>
            searchQuery ? story.name.toLowerCase().includes(searchQuery.toLowerCase()) : true
        );
        setFilteredStories(filtered);
    }, [searchQuery, stories]);

    useEffect(() => {
        const fetchAcceptanceCriteria = async () => {
            if (selectedStory) {
                try {
                    const response = await axios.get(`/api/acceptance-criteria/story/${selectedStory.user_story_id}`);
                    if (response.data.length > 0) {
                        const firstCriteria = response.data[0];
                        setCriteria(firstCriteria);
                        setDescription(firstCriteria.description);
                    } else {
                        setCriteria(null);
                        setDescription('');
                    }
                } catch (error) {
                    console.error('Error fetching acceptance criteria:', error);
                }
            }
        };
        fetchAcceptanceCriteria();
    }, [selectedStory]);

    const handleSelectStory = (story) => {
        setSelectedStory(story);
    };

    const handleSaveCriteria = async () => {
        setIsSaving(true);
        try {
            if (criteria) {
                await axios.put(`/api/acceptance-criteria/${criteria.acceptance_criteria_id}`, {
                    description
                });
            } else {
                await axios.post('/api/acceptance-criteria', {
                    user_story_id: selectedStory.user_story_id,
                    description
                });
            }
            setSnackbarMessage(t('criteriaUpdatedSuccess'));
            setSnackbarVariant('success');
        } catch (error) {
            console.error('Error saving criteria:', error);
            setSnackbarMessage(t('criteriaUpdateError'));
            setSnackbarVariant('error');
        } finally {
            setIsSaving(false);
            setSnackbarOpen(true);
        }
    };

    const handleDeleteCriteria = async () => {
        if (!criteria) return;

        setIsDeleting(true);
        try {
            await axios.delete(`/api/acceptance-criteria/${criteria.acceptance_criteria_id}`);
            setDescription('');
            setCriteria(null);
            setSnackbarMessage(t('criteriaDeletedSuccess'));
            setSnackbarVariant('success');
        } catch (error) {
            console.error('Error deleting criteria:', error);
            setSnackbarMessage(t('criteriaDeleteError'));
            setSnackbarVariant('error');
        } finally {
            setIsDeleting(false);
            setSnackbarOpen(true);
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleCancel = () => {
        setSelectedStory(null);
        setCriteria(null);
        setDescription('');
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const indexOfLastStory = currentPage * storiesPerPage;
    const indexOfFirstStory = indexOfLastStory - storiesPerPage;
    const currentStories = filteredStories.slice(indexOfFirstStory, indexOfLastStory);

    return (
        <Container>
            <Box mt={2}>
                {!selectedStory && (
                    <Container maxWidth="sm">
                        <Paper elevation={3} style={{ padding: '32px 16px' }}>
                            <Typography variant="h6" gutterBottom>
                                {t('editCriteriaTitle')}
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        select
                                        label={t('selectEpicLabel')}
                                        variant="outlined"
                                        fullWidth
                                        value={selectedEpic}
                                        onChange={(e) => setSelectedEpic(e.target.value)}
                                        disabled={!selectedProduct}
                                    >
                                        {epics.map(e => (
                                            <MenuItem key={e.epic_id} value={e.epic_id}>
                                                {e.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                {selectedEpic && (
                                    <>
                                        <Grid item xs={12}>
                                            <TextField
                                                label={t('searchByNameLabel')}
                                                variant="outlined"
                                                fullWidth
                                                value={searchQuery}
                                                onChange={(e) => setSearchQuery(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            {currentStories.map(story => (
                                                <Button
                                                    key={story.user_story_id}
                                                    variant="outlined"
                                                    fullWidth
                                                    style={{ margin: '4px 0' }}
                                                    onClick={() => handleSelectStory(story)}
                                                >
                                                    {story.name}
                                                </Button>
                                            ))}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box display="flex" justifyContent="center" mt={2}>
                                                <Pagination
                                                    count={Math.ceil(filteredStories.length / storiesPerPage)}
                                                    page={currentPage}
                                                    onChange={handlePageChange}
                                                    color="primary"
                                                />
                                            </Box>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </Paper>
                    </Container>
                )}
                {selectedStory && (
                    <Paper elevation={3} style={{ padding: '32px 16px' }}>
                        <Typography variant="h6" gutterBottom style={{ marginBottom: '24px' }}>
                            {t('editCriteriaTitle')}
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    label={t('criteriaDescriptionLabel')}
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={4}
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', gap: '8px' }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSaveCriteria}
                                        disabled={isSaving}
                                    >
                                        {isSaving ? <CircularProgress size={24} /> : t('saveCriteria')}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        onClick={handleDeleteCriteria}
                                        disabled={isDeleting}
                                    >
                                        {isDeleting ? <CircularProgress size={24} /> : t('deleteCriteria')}
                                    </Button>
                                </div>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={handleCancel}
                                >
                                    {t('cancelCriteria')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                )}
            </Box>
            <CustomSnackbar
                open={snackbarOpen}
                message={snackbarMessage}
                onClose={handleSnackbarClose}
                variant={snackbarVariant}
            />
        </Container>
    );
};

export default EditCriteria;
