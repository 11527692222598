import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Typography, IconButton, Tooltip, Box } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import '../css/AcceptanceCriteriaCard.css';  // Importa los estilos específicos

function AcceptanceCriteriaCard({ acceptanceCriteria, onCopy }) {
    const formattedCriteria = acceptanceCriteria.split('\n').map(line => line.trim()).filter(line => line.length > 0);
    const { t } = useTranslation();

    return (
        <Card className="customCriteriaCard criteriaCard">
            <Box className="criteriaCardBox">
                <Box className="criteriaCardText">
                    <Typography className="criteriaTitle">{t('cardAcceptanceCriteriaTitle')}</Typography>
                    {formattedCriteria.map((criterion, index) => (
                        <Typography key={index} className="criteriaText">
                            {criterion}
                        </Typography>
                    ))}
                </Box>
                <Box className="actionButtons">
                    <Tooltip title="Copy to Clipboard">
                        <IconButton onClick={onCopy} color="primary">
                            <ContentCopyIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
        </Card>
    );
}

export default AcceptanceCriteriaCard;
