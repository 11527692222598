import React from 'react';
import { useTranslation } from 'react-i18next';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { FlashOn as FlashOnIcon, Timer as TimerIcon, Build as BuildIcon } from '@mui/icons-material';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Footer from '../components/Footer';
import '../css/LandingPage.css';
import '../css/customMaterial.css';

function HomePage() {
    const { t } = useTranslation();

    const createListItems = (descKey) => {
        const items = t(descKey).split('|');
        return items.map((item, index) => (
            <li key={index} style={{ listStyleType: 'circle' }}>{item.trim()}</li>
        ));
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Container component="main" maxWidth="md" sx={{ mt: 4 }}>
                {/* Intro Section */}
                <Box className="intro-section" sx={{ textAlign: 'center' }}>
                    <Typography variant="h4" className="home-title-text">{t('homeTitle')}</Typography>
                    <Typography variant="body1" className="intro-text">{t('introText')}</Typography>
                    <img src="img/robot_agile_white.webp" alt={t('introImageAlt')} className="intro-image" />
                    <Button variant="contained" color="primary" href="/SignUp" className="start-button">{t('landingStartButton')}</Button>
                </Box>

                {/* Content Section */}
                <Box className="content-section">
                    <Typography variant="h6" className="advantage-section-title">{t('advantagesLanding')}</Typography>
                </Box>

                {/* Advantages Cards */}
                <Box className="advantage-cards feature-cards-container" sx={{ mt: 4 }}>
                    {[1, 2, 3].map((i) => (
                        <Card key={i} className="feature-card">
                            <CardMedia>
                                {i === 1 ? <FlashOnIcon className="feature-icon" /> : i === 2 ? <TimerIcon className="feature-icon" /> : <BuildIcon className="feature-icon" />}
                            </CardMedia>
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div" className="feature-title">
                                    {t(`titleBlock${i}Landing`)}
                                </Typography>
                                <ul className="feature-description">
                                    {createListItems(`descBlock${i}Landing`)}
                                </ul>
                            </CardContent>
                        </Card>
                    ))}
                </Box>
            </Container>
            {/* <Footer /> */}
        </Box>
    );
}

export default HomePage;
