import React, { useState } from 'react';
import { Container, Box, Tabs, Tab, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ProductForm from './ProductForm';
import ProductSearch from './ProductSearch';
import CustomSnackbar from '../messages/CustomSnackbar';
import { useProduct } from '../../context/ProductContext';

const ProductManager = () => {
    const [tab, setTab] = useState(0);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarVariant, setSnackbarVariant] = useState('success');
    const { t } = useTranslation();
    const isMobile = useMediaQuery('(max-width:600px)');
    const { fetchProducts } = useProduct();

    const handleChangeTab = (event, newValue) => {
        setTab(newValue);
        setSelectedProduct(null);
    };

    const handleSelectProduct = (product) => {
        setSelectedProduct(product);
        setTab(1);
    };

    const handleSaveProduct = (updatedProduct, success) => {
        if (success) {
            setSnackbarMessage(t('productSavedSuccess'));
            setSnackbarVariant('success');
            fetchProducts(); // Actualiza la lista de productos
        } else {
            setSnackbarMessage(t('productSaveError'));
            setSnackbarVariant('error');
        }
        setSnackbarOpen(true);
        setSelectedProduct(null);
        if (tab === 0) setTab(0); // Reset the Add Product tab
    };

    const handleDeleteProduct = (productId, success) => {
        if (success) {
            setSnackbarMessage(t('productDeletedSuccess'));
            setSnackbarVariant('success');
            fetchProducts(); // Actualiza la lista de productos
        } else {
            setSnackbarMessage(t('productDeleteError'));
            setSnackbarVariant('error');
        }
        setSnackbarOpen(true);
        setSelectedProduct(null);
    };

    const handleCancel = () => {
        setSelectedProduct(null);
        if (tab === 0) setTab(0); // Reset the Add Product tab
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <Container>
            <Box display="flex" justifyContent="center" mb={2}>
                <Tabs
                    value={tab}
                    onChange={handleChangeTab}
                    centered={!isMobile}
                    variant={isMobile ? 'scrollable' : 'standard'}
                    scrollButtons={isMobile ? 'on' : 'auto'}
                >
                    <Tab label={t('addProductTitle')} />
                    <Tab label={t('editProductTitle')} />
                </Tabs>
            </Box>
            <Box mt={2}>
                {tab === 0 && <ProductForm key="add" onSave={handleSaveProduct} onCancel={handleCancel} />}
                {tab === 1 && !selectedProduct && <ProductSearch onSelectProduct={handleSelectProduct} />}
                {tab === 1 && selectedProduct && (
                    <ProductForm
                        key={selectedProduct.product_id}
                        product={selectedProduct}
                        onSave={handleSaveProduct}
                        onCancel={handleCancel}
                        onDelete={handleDeleteProduct}
                    />
                )}
            </Box>
            <CustomSnackbar
                open={snackbarOpen}
                message={snackbarMessage}
                onClose={handleSnackbarClose}
                variant={snackbarVariant}
            />
        </Container>
    );
};

export default ProductManager;
