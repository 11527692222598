import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, TextField, Button, Box, CircularProgress, Typography } from '@mui/material';
import StoryCard from './StoryCard';
import '../css/customMaterial.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const StoryForm = () => {
    const [context, setContext] = useState('');
    const [functionality, setFunctionality] = useState('');
    const [originalStory, setOriginalStory] = useState('');
    const [decomposedStories, setDecomposedStories] = useState([]);
    const [additionalInfo, setAdditionalInfo] = useState('');
    const [isGeneratingStory, setIsGeneratingStory] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isDecomposing, setIsDecomposing] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showDecomposition, setShowDecomposition] = useState(false);

    const { t } = useTranslation();

    const formatGeneratedStory = (storyText) => {
        let cleanText = storyText.replace(/[*]/g, '').trim();
        if (!cleanText.startsWith('Title:') && !cleanText.startsWith('Título:')) {
            cleanText = cleanText.replace(/^/, 'Title: ').replace(/\n/, '\n\n');
        }
        return cleanText;
    };

    const extractTitleFromStory = (storyText) => {
        const titleMatch = storyText.match(/(?:Title|Título): (.*?)(?=\n|$)/);
        return titleMatch ? titleMatch[1].trim() : 'User Story';
    };

    const extractDescriptionFromStory = (storyText) => {
        return storyText.replace(/(?:Title|Título): .*?(?=\n|$)\n?/, '').trim();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsGeneratingStory(true);
        setAdditionalInfo('');
        setDecomposedStories([]);
        const prompt = t('promptTemplate', { context, functionality });
        try {
            const data = await sendPromptToServer(prompt, 'en', false);
            const formattedStory = formatGeneratedStory(data.generatedText);
            setOriginalStory(formattedStory);
        } catch (error) {
            console.error("Error generating user story:", error);
            setErrorMessage(`${t('errorGeneratingStory')} ${error.message}`);
        } finally {
            setIsGeneratingStory(false);
        }
    };

    async function sendPromptToServer(prompt, type) {
        const body = JSON.stringify({ prompt, type });
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/generate-text`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: body,
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    }

    const handleGenerateAcceptanceCriteria = async (story) => {
        setIsLoading(true);
        const prompt = `Context: ${context}\nOriginal Story: ${originalStory}\nUser Story: ${story}\n\n${t('promptAcceptanceCriteria')}`;
        try {
            const data = await sendPromptToServer(prompt, 'criteria');
            setIsLoading(false);
            return data.generatedText;
        } catch (error) {
            console.error("Error generating acceptance criteria:", error);
            setErrorMessage(`${t('errorGeneratingCriteria')} ${error.message}`);
            setIsLoading(false);
            return '';
        }
    };

    const decomposeStory = async () => {
        setIsDecomposing(true);
        const fullContext = `${context}\n\n${originalStory}\n\n${additionalInfo}`;
        const prompt = t('promptDecomposeTemplate', { fullContext, functionality, originalStory, additionalInfo });
        try {
            const data = await sendPromptToServer(prompt, 'en', true);
            if (data && data.generatedText) {
                const storyParts = data.generatedText.split('\n\n').map(part => part.trim()).filter(part => part.length > 0);
                const decomposedStories = storyParts.map((storyText) => {
                    const formattedStory = formatGeneratedStory(storyText);
                    const title = extractTitleFromStory(formattedStory);
                    const description = extractDescriptionFromStory(formattedStory);
                    return {
                        title: title.trim(),
                        description: description.trim()
                    };
                });
                setDecomposedStories(decomposedStories);
            } else {
                throw new Error(t('errorNoGeneratedText'));
            }
        } catch (error) {
            console.error("Error decomposing user story:", error);
            setErrorMessage(`${t('errorDecomposingStory')} ${error.message}`);
        } finally {
            setIsDecomposing(false);
        }
    };

    const handleCopyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Container component="main" maxWidth="md" sx={{ mt: 4 }}>

                <Box className="content-section">
                    <Typography variant="h6" className="section-title">
                        {t('titleGenerateStory')}
                    </Typography>
                    <Typography variant="body1" className="section-description">
                        {t('descriptionGenerateStory')}
                    </Typography>
                </Box>

                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <Typography variant="caption" className="form-caption caption-text" sx={{ display: 'block', mb: 1, color: 'text.secondary' }}>
                        {t('contextLabel')}
                    </Typography>
                    <TextField
                        className="custom-textfield multiline"
                        required
                        fullWidth
                        id="context"
                        name="context"
                        autoComplete="context"
                        autoFocus
                        multiline
                        rows={4}
                        value={context}
                        onChange={(e) => setContext(e.target.value)}
                        variant="outlined"
                    />
                    <Typography variant="caption" className="form-caption caption-text" sx={{ display: 'block', mb: 1, color: 'text.secondary' }}>
                        {t('functionalityLabel')}
                    </Typography>
                    <TextField
                        className="custom-textfield multiline"
                        required
                        fullWidth
                        id="functionality"
                        name="functionality"
                        autoComplete="functionality"
                        value={functionality}
                        onChange={(e) => setFunctionality(e.target.value)}
                        variant="outlined"
                    />
                    <Button
                        className="custom-button"
                        type="submit"
                        disabled={isGeneratingStory}
                        onClick={handleSubmit}
                    >
                        {isGeneratingStory ? <CircularProgress size={24} /> : t('generateButton')}
                    </Button>
                </Box>

                {errorMessage && <Typography color="error" sx={{ mt: 2 }}>{errorMessage}</Typography>}

                {originalStory && (
                    <StoryCard
                        story={{ title: extractTitleFromStory(originalStory), description: extractDescriptionFromStory(originalStory) }}
                        onCopy={handleCopyToClipboard}
                        onGenerateAcceptanceCriteria={handleGenerateAcceptanceCriteria}
                    />
                )}

                <Box sx={{ mt: 2, mb: 2 }}>
                    <Box sx={{ cursor: 'pointer' }} onClick={() => setShowDecomposition(!showDecomposition)}>
                        <Typography variant="h6" className="decomposeStoriesButton" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            {t('decomposeButton')}
                            {showDecomposition ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </Typography>
                    </Box>
                    {showDecomposition && (
                        <>
                            <Typography variant="caption" className="form-caption caption-text" sx={{ display: 'block', mb: 1, color: 'text.secondary' }}>
                                {t('additionalInfoLabel', { defaultValue: t('additionalInfoPlaceholder') })}
                            </Typography>
                            <TextField
                                className="custom-textfield"
                                required
                                fullWidth
                                id="additionalInfo"
                                name="additionalInfo"
                                multiline
                                rows={3}
                                variant="outlined"
                                value={additionalInfo}
                                onChange={(e) => setAdditionalInfo(e.target.value)}
                            />

                            <Button
                                className="custom-button"
                                onClick={decomposeStory}
                                disabled={isDecomposing}
                            >
                                {isDecomposing ? <CircularProgress size={24} /> : t('generateUserStories')}
                            </Button>

                            {decomposedStories.map((story, index) => (
                                <StoryCard
                                    key={index}
                                    story={story}
                                    onCopy={handleCopyToClipboard}
                                    onGenerateAcceptanceCriteria={handleGenerateAcceptanceCriteria}
                                />
                            ))}
                        </>
                    )}
                </Box>
            </Container>
        </Box>
    );
}

export default StoryForm;
