import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import AcceptanceCriteriaCard from './AcceptanceCriteriaCard';
import '../css/customMaterial.css';

function AcceptanceCriteria() {
    const [context, setContext] = useState('');
    const [userStory, setUserStory] = useState('');
    const [acceptanceCriteria, setAcceptanceCriteria] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const { t } = useTranslation();

    // Handles the submission of the form and generates the acceptance criteria
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        // const prompt = `Context: ${context}\nUser Story: ${userStory}\n\n${t('promptAcceptanceCriteria')}`;
        const prompt = t('acceptanceCriteriaPrompt', { context, userStory, criteria: t('promptAcceptanceCriteria') });
        try {
            const data = await sendPromptToServer(prompt, 'criteria');
            setAcceptanceCriteria(data.generatedText);
        } catch (error) {
            console.error("Error generating acceptance criteria:", error);
            setErrorMessage(`${t('errorGeneratingCriteria')} ${error.message}`);
        } finally {
            setIsLoading(false);
        }
    };

    // Sends prompt to the backend server for generating text
    async function sendPromptToServer(prompt, type) {
        const body = JSON.stringify({ prompt, type });
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/generate-text`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: body,
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    };

    // Copy text to clipboard
    const handleCopyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Container component="main" maxWidth="md" sx={{ mt: 4 }}>
                {/* Content Section */}
                <Box className="content-section">
                    <Typography variant="h6" className="section-title">
                        {t('titleGenerateAcceptanceCriteria')}
                    </Typography>
                    <Typography variant="body1" className="section-description">
                        {t('descriptionAcceptanceCriteria')}
                    </Typography>
                </Box>

                {/* Form Section */}
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <Typography variant="caption" className="form-caption caption-text" sx={{ display: 'block', mb: 1, color: 'text.secondary' }}>
                        {t('contextLabel')}
                    </Typography>
                    <TextField
                        className="custom-textfield multiline"
                        required
                        fullWidth
                        id="context"
                        name="context"
                        autoComplete="context"
                        autoFocus
                        multiline
                        rows={4}
                        value={context}
                        onChange={(e) => setContext(e.target.value)}
                        variant="outlined"
                    />
                    <Typography variant="caption" className="form-caption caption-text" sx={{ display: 'block', mb: 1, color: 'text.secondary' }}>
                        {t('nameUserStoryLabel')}
                    </Typography>
                    <TextField
                        className="custom-textfield multiline"
                        required
                        fullWidth
                        id="userStory"
                        name="userStory"
                        autoComplete="userStory"
                        value={userStory}
                        onChange={(e) => setUserStory(e.target.value)}
                        variant="outlined"
                    />
                    <Button
                        className="custom-button"
                        type="submit"
                        disabled={isLoading}
                    >
                        {isLoading ? <CircularProgress size={24} /> : t('generateAcceptanceCriteriaButton')}
                    </Button>
                </Box>

                {/* Error Message Display */}
                {errorMessage && <Typography color="error" sx={{ mt: 2 }}>{errorMessage}</Typography>}

                {/* Displaying the generated acceptance criteria */}
                {acceptanceCriteria && (
                    <AcceptanceCriteriaCard
                        story={userStory}
                        acceptanceCriteria={acceptanceCriteria}
                        onCopy={() => handleCopyToClipboard(acceptanceCriteria)}
                    />
                )}

            </Container>
        </Box>
    );
}

export default AcceptanceCriteria;
