import React from 'react';
import { Card, CardContent, Typography, IconButton, Box } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SaveIcon from '@mui/icons-material/Save';
import { useTranslation } from 'react-i18next';
import '../../css/EpicCard.css';

const cleanEpicText = (text) => {
    return text.replace(/\*\*(.*?)\*\*/g, '$1').trim();
};

const EpicCard = ({ epic, onSave }) => {
    const { t } = useTranslation();

    const handleCopyToClipboard = () => {
        const cleanTitle = cleanEpicText(epic.title);
        const cleanDescription = cleanEpicText(epic.description);
        const textToCopy = `${cleanTitle}\n${cleanDescription}`;
        navigator.clipboard.writeText(textToCopy);
    };

    return (
        <Card className="customStoryCard storyCard">
            <CardContent>
                <Box className="storyCardBox">
                    <Box className="storyCardText">
                        <Typography className="storyTitle" style={{ fontWeight: 'bold' }}>
                            {epic.title}
                        </Typography>
                        <Typography className="storyDescription">
                            {epic.description}
                        </Typography>
                    </Box>
                    <Box display="flex" flexDirection="column" alignItems="flex-end" mt={2}>
                        <IconButton onClick={handleCopyToClipboard} color="primary" title={t('copyToClipboardButton')}>
                            <ContentCopyIcon />
                        </IconButton>
                        <IconButton onClick={() => onSave(epic)} color="primary" title={t('saveEpicButton')}>
                            <SaveIcon />
                        </IconButton>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
};

export default EpicCard;
