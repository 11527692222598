import React, { useState } from 'react';
import { Card, CardContent, Typography, IconButton, Tooltip, Box, CircularProgress } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';
import '../css/EpicCard.css';  // Asegúrate de que este archivo contiene los nuevos estilos

const EpicCard = ({ epic }) => {

    const [isLoading, setIsLoading] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const { t } = useTranslation();

    const handleCopyToClipboard = () => {
        // Elimina los asteriscos usados para negrita en Markdown y copia el texto limpio
        const cleanText = epic.replace(/\*\*(.*?)\*\*/g, '$1');
        navigator.clipboard.writeText(cleanText);
    };


    const formatEpicText = (text) => {
        const parts = text.split('\n');
        if (parts.length > 1) {
            return (
                <>
                    <Typography className="epicTitle">
                        {parts[0].replace(/\*\*(.*)\*\*/, '$1')}
                    </Typography>
                    <Typography className="epicDescription">{parts[1]}</Typography>
                </>
            );
        } else {
            return <Typography>{text}</Typography>;
        }
    };


    return (
        <Card className="customEpicCard epicCard">
            <Box className="epicCardBox">
                <Box className="epicCardText">
                    <Typography className="epicText">
                        <Box className="epicCardText">
                            {formatEpicText(epic)}
                        </Box>
                    </Typography>
                </Box>
                <Box className="actionButtons">
                    <Tooltip title={t('copyToClipboardButton')}>
                        <IconButton onClick={handleCopyToClipboard} color="primary">
                            <ContentCopyIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
        </Card>
    );
};

export default EpicCard;
