import React, { createContext, useContext, useState, useEffect } from 'react';
import i18n from '../i18n/i18n';

const LanguageContext = createContext();

export function useLanguage() {
    return useContext(LanguageContext);
}

export const LanguageProvider = ({ children }) => {
    const [language, setLanguage] = useState(i18n.language);

    useEffect(() => {
        i18n.changeLanguage(language);
    }, [language]);

    return (
        <LanguageContext.Provider value={{ language, setLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
};
