import React from 'react';
import { Card, CardContent, Typography, IconButton, Box } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SaveIcon from '@mui/icons-material/Save';
import { useTranslation } from 'react-i18next';
import '../../css/StoryCard.css';

const cleanStoryText = (text) => {
    return text.replace(/[*]/g, '').trim();
};

const StoryCard = ({ story, onSave }) => {
    const { t } = useTranslation();

    const handleCopyToClipboard = () => {
        const cleanText = cleanStoryText(story.description);
        navigator.clipboard.writeText(cleanText);
    };

    return (
        <Card className="customStoryCard storyCard">
            <CardContent>
                <Box className="storyCardBox">
                    <Box className="storyCardText">
                        <Typography className="storyTitle" style={{ fontWeight: 'bold' }}>
                            {/* {t('userStoryTitle')}: {story.title} */}
                            {story.title}
                        </Typography>
                        <Typography className="storyDescription">
                            {story.description}
                        </Typography>
                    </Box>
                    <Box display="flex" flexDirection="column" alignItems="flex-end" mt={2}>
                        <IconButton onClick={handleCopyToClipboard} color="primary" title={t('copyToClipboardButton')}>
                            <ContentCopyIcon />
                        </IconButton>
                        <IconButton onClick={() => onSave(story)} color="primary" title={t('saveStoryButton')}>
                            <SaveIcon />
                        </IconButton>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
};

export default StoryCard;
