// Funciona
import React, { useState } from 'react';
import { Container, Box, Tabs, Tab, useMediaQuery, BottomNavigation, BottomNavigationAction } from '@mui/material';
import { useTranslation } from 'react-i18next';
import GenerateStory from './GenerateStory';
import GenerateEpicStories from './GenerateEpicStories'; // Importa el nuevo componente
import AddStory from './AddStory';
import EditStory from './EditStory';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import EpicStoriesIcon from '@mui/icons-material/LibraryBooks'; // Icono para Generate Epic Stories

const StoryManager = () => {
    const [tab, setTab] = useState(0);
    const { t } = useTranslation();
    const isMobile = useMediaQuery('(max-width:1024px)');

    const handleChangeTab = (event, newValue) => {
        setTab(newValue);
    };

    return (
        <Container maxWidth="lg">
            <Box display="flex" justifyContent="center" mb={2}>
                {isMobile ? (
                    <BottomNavigation
                        value={tab}
                        onChange={(event, newValue) => handleChangeTab(event, newValue)}
                        showLabels
                    >
                        <BottomNavigationAction label={t('generateEpicStoriesTitle')} icon={<EpicStoriesIcon />} />
                        <BottomNavigationAction label={t('generateStoryTitle')} icon={<AutoFixHighIcon />} />
                        <BottomNavigationAction label={t('addStoryTitle')} icon={<AddIcon />} />
                        <BottomNavigationAction label={t('editStoryTitle')} icon={<EditIcon />} />
                    </BottomNavigation>
                ) : (
                    <Tabs
                        value={tab}
                        onChange={handleChangeTab}
                        centered
                    >
                        <Tab label={t('generateEpicStoriesTitle')} /> {/* Nueva pestaña */}
                        <Tab label={t('generateStoryTitle')} />
                        <Tab label={t('addStoryTitle')} />
                        <Tab label={t('editStoryTitle')} />
                    </Tabs>
                )}
            </Box>
            <Box mt={2}>
                {tab === 0 && <GenerateEpicStories />} {/* Nuevo componente */}
                {tab === 1 && <GenerateStory />}
                {tab === 2 && <AddStory />}
                {tab === 3 && <EditStory />}
            </Box>
        </Container>
    );
};

export default StoryManager;
