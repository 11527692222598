import React, { useState, useEffect } from 'react';
import { TextField, Button, MenuItem, Grid, Typography, Container, Paper, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import CustomSnackbar from '../messages/CustomSnackbar';
import CriteriaCard from './CriteriaCard';
import { useProduct } from '../../context/ProductContext';

const GenerateCriteria = () => {
    const { selectedProduct } = useProduct();
    const [epic, setEpic] = useState('');
    const [epics, setEpics] = useState([]);
    const [story, setStory] = useState('');
    const [stories, setStories] = useState([]);
    const [context, setContext] = useState('');
    const [storyDescription, setStoryDescription] = useState('');
    const [criteria, setCriteria] = useState('');
    const [isGeneratingCriteria, setIsGeneratingCriteria] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarVariant, setSnackbarVariant] = useState('success');
    const { t } = useTranslation();

    useEffect(() => {
        if (selectedProduct) {
            const fetchEpics = async () => {
                try {
                    const response = await axios.get(`/api/epics/product/${selectedProduct}`);
                    setEpics(response.data);
                } catch (error) {
                    console.error('Error fetching epics:', error);
                }
            };
            fetchEpics();

            const fetchProductContext = async () => {
                try {
                    const response = await axios.get(`/api/products/${selectedProduct}`);
                    setContext(response.data.description);
                } catch (error) {
                    console.error('Error fetching product context:', error);
                }
            };
            fetchProductContext();
        }
    }, [selectedProduct]);

    useEffect(() => {
        if (epic) {
            const fetchStories = async () => {
                try {
                    const response = await axios.get(`/api/stories/epic/${epic}`);
                    setStories(response.data);
                } catch (error) {
                    console.error('Error fetching stories:', error);
                }
            };
            fetchStories();
        }
    }, [epic]);

    useEffect(() => {
        if (story) {
            const selectedStory = stories.find(s => s.user_story_id === story);
            if (selectedStory) {
                setStoryDescription(selectedStory.description);
            }
        }
    }, [story, stories]);

    const handleGenerateCriteria = async (e) => {
        e.preventDefault();
        setIsGeneratingCriteria(true);
        const prompt = t('promptAcceptanceCriteria', { context, storyDescription });
        try {
            const data = await sendPromptToServer(prompt, 'criteria');
            setCriteria(data.generatedText);
        } catch (error) {
            console.error("Error generating acceptance criteria:", error);
            setErrorMessage(`${t('errorGeneratingCriteria')} ${error.message}`);
        } finally {
            setIsGeneratingCriteria(false);
        }
    };

    async function sendPromptToServer(prompt, type) {
        const body = JSON.stringify({ prompt, type });
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/generate-text`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: body,
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    }

    const handleSaveCriteria = async () => {
        try {
            await axios.post('/api/acceptance-criteria', {
                user_story_id: story,
                description: criteria
            });
            setSnackbarMessage(t('criteriaSavedSuccess'));
            setSnackbarVariant('success');
        } catch (error) {
            console.error('Error saving criteria:', error);
            setSnackbarMessage(t('criteriaSaveError'));
            setSnackbarVariant('error');
        } finally {
            setSnackbarOpen(true);
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleCopyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
    };

    return (
        <Container maxWidth="md">
            <Paper elevation={3} style={{ padding: '32px 16px' }}>
                <Typography variant="h6" gutterBottom>
                    {t('generateCriteriaTitle')}
                </Typography>
                <Grid container spacing={2}>
                    {/*                     <Grid item xs={12}>
                        <Typography variant="body1" gutterBottom>
                            {t('selectedProduct')}: {selectedProduct ? selectedProduct : t('noProductSelected')}
                        </Typography>
                    </Grid> */}
                    <Grid item xs={12}>
                        <TextField
                            select
                            label={t('selectEpicLabel')}
                            variant="outlined"
                            fullWidth
                            value={epic}
                            onChange={(e) => setEpic(e.target.value)}
                            disabled={!selectedProduct}
                            id="epic"
                        >
                            {epics.map(e => (
                                <MenuItem key={e.epic_id} value={e.epic_id}>
                                    {e.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            select
                            label={t('selectStoryLabel')}
                            variant="outlined"
                            fullWidth
                            value={story}
                            onChange={(e) => setStory(e.target.value)}
                            disabled={!epic}
                            id="story"
                        >
                            {stories.map(s => (
                                <MenuItem key={s.user_story_id} value={s.user_story_id}>
                                    {s.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={handleGenerateCriteria}
                            disabled={!story || isGeneratingCriteria}
                        >
                            {isGeneratingCriteria ? <CircularProgress size={24} /> : t('generateCriteriaButton')}
                        </Button>
                    </Grid>
                </Grid>
                {errorMessage && <Typography color="error" sx={{ mt: 2 }}>{errorMessage}</Typography>}
                {criteria && (
                    <CriteriaCard
                        criteria={criteria}
                        onSave={handleSaveCriteria}
                        onCopy={() => handleCopyToClipboard(criteria)}
                    />
                )}
            </Paper>
            <CustomSnackbar
                open={snackbarOpen}
                message={snackbarMessage}
                onClose={handleSnackbarClose}
                variant={snackbarVariant}
            />
        </Container>
    );
};

export default GenerateCriteria;
