import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Button, Box, Container, Snackbar } from '@mui/material';
import { useProduct } from '../../context/ProductContext';
import '../../css/WelcomeCard.css';

const WelcomeCard = ({ setFormType }) => {
    const { t } = useTranslation();
    const { isProductSelected, products, hasActiveProducts } = useProduct();
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const showSnackbar = () => {
        setSnackbarOpen(true);
    };

    const handleButtonClick = (formType) => {
        if (isProductSelected() && hasActiveProducts()) {
            setFormType(formType);
        } else {
            showSnackbar();
        }
    };

    const handleStartClick = () => {
        setFormType('ProductForm');
    };

    // Filtrar productos que están activos o en desarrollo
    const filteredProducts = products.filter(
        product => product.status === 'active' || product.status === 'in_development'
    );

    return (
        <Container maxWidth="sm">
            <Box className="welcome-card">
                <Typography variant="h5" className="welcome-card-title" gutterBottom>
                    {t('welcomeCardTitle')}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    {filteredProducts.length === 0 ? t('welcomeCardDescriptionNoProductPrivate') : t('welcomeCardDescriptionPrivate')}
                </Typography>
                <img src='img/welcomeCard.jpg' alt="Welcome" className="welcome-card-image" />
                <Box className="welcome-card-buttons">
                    {filteredProducts.length === 0 ? (
                        <Button variant="contained" color="primary" onClick={handleStartClick}>
                            {t('welcomeStartButton')}
                        </Button>
                    ) : (
                        <>
                            <Button variant="contained" color="primary" onClick={() => handleButtonClick('EpicManager')}>
                                {t('welcomeEpicButton')}
                            </Button>
                            <Button variant="contained" color="primary" onClick={() => handleButtonClick('StoryManager')}>
                                {t('welcomeStoryButton')}
                            </Button>
                            <Button variant="contained" color="primary" onClick={() => handleButtonClick('CriteriaManager')}>
                                {t('welcomeCriteriaButton')}
                            </Button>
                        </>
                    )}
                </Box>
            </Box>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                message={t('selectProductSnackbar')}
            />
        </Container>
    );
};

export default WelcomeCard;
