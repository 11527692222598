import React, { useState } from 'react';
import { Container, Box, Tabs, Tab, useMediaQuery, BottomNavigation, BottomNavigationAction } from '@mui/material';
import { useTranslation } from 'react-i18next';
import GenerateCriteria from './GenerateCriteria';
import EditCriteria from './EditCriteria';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh'; // Icono para Generate Criteria
import EditIcon from '@mui/icons-material/Edit';

const CriteriaManager = () => {
    const [tab, setTab] = useState(0);
    const { t } = useTranslation();
    const isMobile = useMediaQuery('(max-width:600px)');

    const handleChangeTab = (event, newValue) => {
        setTab(newValue);
    };

    return (
        <Container maxWidth="lg">
            <Box display="flex" justifyContent="center" mb={2}>
                {isMobile ? (
                    <BottomNavigation
                        value={tab}
                        onChange={(event, newValue) => handleChangeTab(event, newValue)}
                        showLabels
                    >
                        <BottomNavigationAction label={t('generateCriteriaTitle')} icon={<AutoFixHighIcon />} />
                        <BottomNavigationAction label={t('editCriteriaTitle')} icon={<EditIcon />} />
                    </BottomNavigation>
                ) : (
                    <Tabs
                        value={tab}
                        onChange={handleChangeTab}
                        centered
                    >
                        <Tab label={t('generateCriteriaTitle')} />
                        <Tab label={t('editCriteriaTitle')} />
                    </Tabs>
                )}
            </Box>
            <Box mt={2}>
                {tab === 0 && <GenerateCriteria />}
                {tab === 1 && <EditCriteria />}
            </Box>
        </Container>
    );
};

export default CriteriaManager;
