import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import axios from 'axios';

const useAuth = () => {
    const [auth, setAuth] = React.useState(false);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        const checkAuth = async () => {
            try {
                const response = await axios.get('/api/current_user');
                setAuth(true);
            } catch (error) {
                console.error('Not authenticated:', error);
                setAuth(false);
            } finally {
                setLoading(false);
            }
        };
        checkAuth();
    }, []);

    return { auth, loading };
};

const PrivateRoute = ({ children }) => {
    const { auth, loading } = useAuth();

    if (loading) {
        return;
    }

    if (!auth) {
        return <Navigate to="/SignIn" />;
    }

    return (
        <>
            {children ? children : <Outlet />}
        </>
    );
};

export default PrivateRoute;
