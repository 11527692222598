import React, { useState } from 'react';
import { Card, CardContent, Typography, IconButton, Tooltip, Box, CircularProgress } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';
import '../css/StoryCard.css';

const cleanStoryText = (text) => {
    return text.replace(/[*]/g, '').trim();
};

const StoryCard = ({ story, onGenerateAcceptanceCriteria }) => {
    const [acceptanceCriteria, setAcceptanceCriteria] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const { t } = useTranslation();

    const handleGenerateAcceptanceCriteriaClick = async () => {
        setIsLoading(true);
        const criteria = await onGenerateAcceptanceCriteria(story);
        setAcceptanceCriteria(criteria);
        setIsLoaded(true);
        setIsLoading(false);
    };

    const handleCopyToClipboard = () => {
        let textToCopy = story.description;
        if (acceptanceCriteria) {
            textToCopy += "\n\n" + t('cardAcceptanceCriteriaTitle') + "\n" + acceptanceCriteria;
        }
        navigator.clipboard.writeText(textToCopy);
    };

    const formattedCriteria = acceptanceCriteria.split('\n').map(line => line.trim()).filter(line => line.length > 0);

    return (
        <Card className="customStoryCard storyCard">
            <Box className="storyCardBox">
                <Box className="storyCardText">
                    <Typography className="storyTitle" style={{ fontWeight: 'bold' }}>
                        {story.title}
                    </Typography>
                    <Typography className="storyDescription">
                        {story.description}
                    </Typography>
                    {acceptanceCriteria && (
                        <>
                            <Typography className="criteriaTitle">
                                {t('cardAcceptanceCriteriaTitle')}
                            </Typography>
                            {formattedCriteria.map((criteria, index) => (
                                <Typography key={index} className="criteriaText">
                                    {`${criteria.match(/^\d+/) ? `${criteria.match(/^\d+/)[0]}.` : ''} ${criteria.replace(/^\d+\.\s*/, '')}`}
                                </Typography>
                            ))}
                        </>
                    )}
                </Box>
                <Box className="actionButtons">
                    <Tooltip title={t('copyToClipboardButton')}>
                        <IconButton onClick={handleCopyToClipboard} color="primary">
                            <ContentCopyIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={isLoading ? "Loading..." : t('generateAcceptanceCriButton')}>
                        <IconButton onClick={handleGenerateAcceptanceCriteriaClick} color="secondary" disabled={isLoaded}>
                            {isLoading ? <CircularProgress size={24} /> : <CheckIcon />}
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
        </Card>
    );
};

export default StoryCard;
