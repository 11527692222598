import React, { useState } from 'react';
import { TextField, Button, Grid, Typography, Container, Paper, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import EpicCard from './EpicCard';
import CustomSnackbar from '../messages/CustomSnackbar';
import { useProduct } from '../../context/ProductContext';

const cleanEpicText = (text) => {
    return text.replace(/\*\*(.*?)\*\*/g, '$1').replace(/[:#]/g, '').trim();
};

const extractTitleAndDescription = (text) => {
    const parts = text.split('\n').filter(part => part.trim() !== '');
    const title = parts.length > 0 ? cleanEpicText(parts[0]) : '';
    const description = parts.slice(1).join('\n').trim();
    return { title, description };
};

const GenerateEpic = () => {
    const { selectedProduct } = useProduct();
    const [epics, setEpics] = useState([]);
    const [isGenerating, setIsGenerating] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarVariant, setSnackbarVariant] = useState('success');
    const { t } = useTranslation();

    const handleGenerateEpics = async () => {
        setIsGenerating(true);
        try {
            if (!selectedProduct) {
                throw new Error(t('noSelectedProductError'));
            }

            const contextResponse = await axios.get(`/api/epics/product/${selectedProduct}/context`);
            const prompt = t('epicsPrompt', { context: contextResponse.data.context });
            const response = await axios.post('/generate-text', { prompt });

            const generatedEpics = response.data.generatedText.split('\n\n').map(epicText => extractTitleAndDescription(epicText));

            const validEpics = generatedEpics.filter(epic => epic.title && epic.description);
            if (validEpics.length === 0) {
                throw new Error(t('noGeneratedEpicsError'));
            }
            setEpics(validEpics);
        } catch (error) {
            console.error('Error generating epics:', error);
            setSnackbarMessage(error.message || t('epicGenerationError'));
            setSnackbarVariant('error');
            setSnackbarOpen(true);
        } finally {
            setIsGenerating(false);
        }
    };

    const handleSaveEpic = async (epic) => {
        try {
            await axios.post('/api/epics', {
                name: epic.title,
                description: epic.description,
                product_id: selectedProduct
            });
            setSnackbarMessage(t('epicSavedSuccess'));
            setSnackbarVariant('success');
        } catch (error) {
            console.error('Error saving epic:', error);
            setSnackbarMessage(t('epicSaveError'));
            setSnackbarVariant('error');
        } finally {
            setSnackbarOpen(true);
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <Container maxWidth="sm">
            <Paper elevation={3} style={{ padding: '32px 16px' }}>
                <Typography variant="h6" gutterBottom>
                    {t('generateEpicsTitle')}
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {/* El selector de producto se elimina aquí, ya que está en el menú lateral */}
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={handleGenerateEpics}
                            disabled={!selectedProduct || isGenerating}
                        >
                            {isGenerating ? <CircularProgress size={24} /> : t('generateEpicsButton')}
                        </Button>
                    </Grid>
                </Grid>
                {epics.map((epic, index) => (
                    <EpicCard key={index} epic={epic} onSave={handleSaveEpic} />
                ))}
            </Paper>
            <CustomSnackbar
                open={snackbarOpen}
                message={snackbarMessage}
                onClose={handleSnackbarClose}
                variant={snackbarVariant}
            />
        </Container>
    );
};

export default GenerateEpic;
