import React from 'react';
import { Snackbar, SnackbarContent } from '@mui/material';
import { styled } from '@mui/system';

const StyledSnackbarContent = styled(SnackbarContent)(({ theme, variant }) => ({
    backgroundColor: variant === 'success' ? '#4caf50' : '#f44336', // Verde para éxito, rojo para error
    textAlign: 'right', // Alinear texto a la derecha
}));

const CustomSnackbar = ({ open, message, onClose, variant }) => {
    return (
        <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={open}
            autoHideDuration={6000}
            onClose={onClose}
        >
            <StyledSnackbarContent
                message={message}
                variant={variant}
            />
        </Snackbar>
    );
};

export default CustomSnackbar;
