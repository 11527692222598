import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, Button, CssBaseline, TextField, Link, Grid, Box, Typography, Container } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';

const theme = createTheme();

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [canResend, setCanResend] = useState(true); // Inicialmente habilitado
    const [counter, setCounter] = useState(0); // Inicialmente sin contador

    const navigate = useNavigate();

    useEffect(() => {
        let timer;
        if (counter > 0) {
            timer = setTimeout(() => setCounter(counter - 1), 1000);
        } else {
            setCanResend(true);
        }
        return () => clearTimeout(timer);
    }, [counter]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            await axios.post('/api/forgot-password', { email });
            setMessage('If this email is registered, a password reset link has been sent. Please check your email (including spam folder).');
            setError('');
            setCounter(120); // Establecer el contador en 2 minutos
            setCanResend(false);
        } catch (error) {
            setError('Error sending password reset email');
            setMessage('');
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Forgot Password
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        {message && (
                            <Typography color="primary" align="center">
                                {message}
                            </Typography>
                        )}
                        {error && (
                            <Typography color="error" align="center">
                                {error}
                            </Typography>
                        )}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            disabled={!canResend}
                        >
                            Send Reset Link
                        </Button>
                        {counter > 0 && (
                            <Typography color="textSecondary" align="center">
                                You can resend the link in {counter} seconds.
                            </Typography>
                        )}
                        <Grid container>
                            <Grid item xs>
                                <Link href="/SignIn" variant="body2">
                                    Back to Sign In
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
};

export default ForgotPassword;
