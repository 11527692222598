import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

const ProductContext = createContext();

export const ProductProvider = ({ children }) => {
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [products, setProducts] = useState([]);

    const fetchProducts = async () => {
        try {
            const response = await axios.get('/api/products');
            setProducts(response.data);
        } catch (error) {
            console.error('Error fetching products:', error);
        }
    };

    useEffect(() => {
        fetchProducts();
    }, []);

    const isProductSelected = () => {
        return !!selectedProduct;
    };

    const hasActiveProducts = () => {
        return products.some(product => product.status === 'active' || product.status === 'in_development');
    };

    return (
        <ProductContext.Provider value={{ selectedProduct, setSelectedProduct, isProductSelected, products, fetchProducts, hasActiveProducts }}>
            {children}
        </ProductContext.Provider>
    );
};

export const useProduct = () => useContext(ProductContext);
