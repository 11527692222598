import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, Button, Select, MenuItem, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useTheme } from '@mui/material/styles';

const NavBar = () => {
    const theme = useTheme();
    const { i18n } = useTranslation();
    const [language, setLanguage] = useState(i18n.language);
    const { user, signOut, fetchUser } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        fetchUser();
    }, [fetchUser]);

    useEffect(() => {
        setLanguage(i18n.language);
    }, [i18n.language]);

    const handleLanguageChange = (event) => {
        const selectedLanguage = event.target.value;
        setLanguage(selectedLanguage);
        i18n.changeLanguage(selectedLanguage);
    };

    const handleSignIn = () => {
        navigate('/SignIn');
    };

    const handleSignOut = async () => {
        await signOut();
        navigate('/SignIn');
    };

    const handleLogoClick = () => {
        navigate('/');
    };

    return (
        <AppBar position="static">
            <Toolbar>
                <img
                    src="/idea_logo.webp"
                    alt="Logo"
                    style={{
                        height: '36px', // Ajusta esto según sea necesario
                        marginRight: theme.spacing(2),
                    }}
                />
                <Typography
                    variant="h6"
                    component="div"
                    sx={{ flexGrow: 1, cursor: 'pointer' }}
                    onClick={handleLogoClick}
                >
                    AI PO Helper
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Select
                        value={language}
                        onChange={handleLanguageChange}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        sx={{
                            color: 'white',
                            borderColor: 'transparent',
                            '.MuiSelect-icon': { color: 'white' },
                            '.MuiOutlinedInput-notchedOutline': { borderColor: 'transparent' },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'transparent',
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'transparent',
                            },
                        }}
                    >
                        <MenuItem value="en">EN</MenuItem>
                        <MenuItem value="es">ES</MenuItem>
                    </Select>
                    {user ? (
                        <Button color="inherit" onClick={handleSignOut} sx={{ marginLeft: 2 }}>
                            Sign Out
                        </Button>
                    ) : (
                        <Button color="inherit" onClick={handleSignIn}>
                            Sign In
                        </Button>
                    )}
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default NavBar;
