import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
    en: {
        translation: {
            header: "AI PO Helper",
            contextLabel: "Provide a product description: what it is, its purpose, target audience, features, technologies, and any relevant information",
            functionalityLabel: "Name the specific functionality to be developed",
            generateButton: "Generate user story",
            generatingButton: "Generating...",
            decomposeButton: "Break it down into more stories",
            copyButton: "Copy",
            userStory: "User story:",
            additionalInfoPlaceholder: "Enter additional information (optional)",
            errorGeneratingStory: "Error generating user story:",
            generateUserStories: "Break down user story",
            generatingUserStories: "Breaking down user stories...",
            decomposedStories: "Broken down stories:",
            navBarTitle: "",
            additionalInfoHeader: "Additional story information",
            introText: "AI PO Helper is a platform that, through Artificial Intelligence, helps you optimize your tasks as a Product Owner, saving you time and supporting you in developing high-quality products.",
            describeProduct: "Describe your product",
            promptTemplate: "Given the context of development and product construction, including user details and purpose: '{{context}}'.\n\nGenerate a user story based on the specific functionality: '{{functionality}}', in the format: 'As a <role>, I want <action to be taken> so that <goal>. The story should start with 'Title: ' followed by a brief title summarizing the main action in a few words, and then the story text. Keep the story text concise, short and direct (maximum 4 lines long)",            // promptTemplate: "Given the context of development and product construction, including user details and purpose: '{{context}}'.\n\nGenerate a user story based on the specific functionality: '{{functionality}}', in the format: 'As a <role>, I want <action to be taken> so that <goal>. The story should start with 'Title: ' followed by a brief title summarizing the main action in a few words, and then the story text.'",
            promptDecomposeTemplate: "Based on the original story: '{{originalStory}}', and considering the additional context provided: '{{additionalInfo}}', please generate more specific user stories that are directly related to the original functionality and context. Each story should start with 'Title: ' followed by a brief title summarizing the main action in a few words, and then the story text. Each story should be separated by line breaks, with no numbers or special characters before or after the story text.",
            homeTitle: "Optimize your productivity in the world of agility with AI",
            titleGenerateStory: "User Stories",
            homeTitleGenerateStory: "Create user stories easily",
            homeDescriptionGenerateStory: "Boost your team's understanding and achieve better results!",
            descriptionGenerateStory: "Focus on what really matters and generate complete, high-quality user stories",
            cardAcceptanceCriteriaTitle: "Acceptance criteria",
            generateAcceptanceCriButton: "Generate acceptance criteria",
            copyToClipboardButton: "Copy to clipboard",
            landingStartButton: "Get Started for Free!",
            advantagesLanding: "Highlights",
            titleBlock1Landing: "Automate your stories",
            "descBlock1Landing": "Automatically generates user stories.|Captures requirements with ease and accuracy.|Ideal for maintaining consistency in project documentation.",
            titleBlock2Landing: "Save time",
            "descBlock2Landing": "Drastically reduces planning time.|Frees up resources for development and design.|Automates repetitive processes and improves efficiency.",
            titleBlock3Landing: "Create better products",
            "descBlock3Landing": "Enhances the quality of the final product.|Ensures that user and stakeholder expectations are clear and achievable.|Minimizes the risks of misunderstandings and ensures a common understanding.",
            "generateEpicsButton": "Generate",
            "generateEpicsDesc": "Divide your product development into epics to plan its construction in the best way possible",
            "titleGenerateAcceptanceCriteria": "Acceptance Criteria",
            "descriptionAcceptanceCriteria": "Generate the acceptance criteria for your user stories.",
            "nameUserStoryLabel": "Enter the user story from which you want the acceptance criteria.",
            "generateAcceptanceCriteriaButton": "Generate Acceptance Criteria",
            "promptAcceptanceCriteria": 'Generate an enumerated list of detailed acceptance criteria, each separated by a line break. The focus of the criteria should be on what the following user story provided says which is Story: {{storyDescription}} and taking account the context of the product which is Context: {{context}}, make sure to cover all technical and functional aspects. Respond only with the list.',
            "workspaceMainTitle": "Select an option from the menu",
            "menuButtonEpics": "Epics",
            "menuButtonUserStories": "User Stories",
            "menuButtonCriteria": "Acceptance Criteria",
            // WelcomeCard translations
            "welcomeCardTitle": "AI PO Helper",
            "welcomeCardDescription": "Select an option from the menu to get started, or use the buttons below to create new content.",
            "welcomeCardDescriptionPrivate": "Welcome! Select a product from the menu to get started or create one in the dedicated section.",
            "welcomeCardDescriptionNoProductPrivate": "Welcome! Create a new product to get started.",
            "welcomeEpicButton": "Epic",
            "welcomeStoryButton": "User Story",
            "welcomeCriteriaButton": "Acceptance Criteria",
            "welcomeStartButton": "Start",
            // ProductForm
            createProductDesc: "A product is an item or system developed to meet user needs, delivering value through iterative and incremental development.",
            createProductTitle: "Product",
            productNameLabel: "Product Name",
            productDescriptionLabel: "Product Description",
            saveProduct: "Save",
            cancelProduct: "Cancel",
            editProductTitle: "Edit Product",
            addProductTitle: "Add Product",
            searchProductTitle: "Search and Edit Product",
            filterStatusLabel: "Filter by Status",
            searchByNameLabel: "Search by Name",
            productSavedSuccess: "Product saved successfully",
            productSaveError: "Error saving product",
            deleteProduct: "Delete",
            productDeletedSuccess: "Product deleted successfully",
            productDeletedError: "Error deleting product",
            productAddDescPlaceHolder: "Include at least the product's purpose, main users, features, and technical aspects. The more detailed, the better the quality",
            // Menú
            menuButtonProduct: "Products",
            menuButtonAddProduct: "Add Product",
            menuButtonEditProduct: "Edit Product",
            // Epics
            generateEpicsTitle: "Generate Epics",
            addEpicTitle: "Add Epic",
            editEpicTitle: "Edit Epic",
            all: "All",
            active: "Active",
            inactive: "Inactive",
            in_development: "In Development",
            "epicsPrompt": `Generate all the necessary epics to successfully develop the product based on the provided product description: "{{context}}". For each epic, provide a non-generic title and a description of no more than 3 lines. Only print the generated epic title and its description, do not use list numbers or colons (:) after the epic title. Strictly adhere to the format, with a double line break between each epic. Format: Title \n Description \n\n Title \n Description \n\n ...`,
            epicTitleLabel: "Epic Title",
            epicDescriptionLabel: "Epic Description",
            saveEpic: "Save",
            cancelEpic: "Cancel",
            epicSavedSuccess: "Epic saved successfully!",
            epicSaveError: "Error saving epic.",
            epicUpdatedSuccess: "Epic updated successfully!",
            epicUpdateError: "Error updating epic.",
            epicDeletedSuccess: "Epic deleted successfully!",
            epicDeleteError: "Error deleting epic.",
            deleteEpic: "Delete",
            // User Stories
            "generateStoryTitle": "Generate Story",
            "addStoryTitle": "Add Story",
            "editStoryTitle": "Edit Story",
            "selectStoryLabel": "Select Story",
            "storyTitleLabel": "Story Title",
            "storyDescriptionLabel": "Story Description",
            "generateStoriesButton": "Generate User Story",
            "saveStory": "Save",
            "cancelStory": "Cancel",
            "deleteStory": "Delete",
            "storySavedSuccess": "Story saved successfully",
            "storySaveError": "Error saving story",
            "storyUpdatedSuccess": "Story updated successfully",
            "storyUpdateError": "Error updating story",
            "storyDeletedSuccess": "Story deleted successfully",
            "storyDeleteError": "Error deleting story",
            "saveStoryButton": "Save story",
            "storiesPrompt": "Generate a series of user stories based on the provided epic context: '{{context}}'. For each story, provide a title and a description. Do not enumerate the stories. Each story and its description should be separated from the next by two line breaks.",
            // Acceptance Criteria
            "generateCriteriaTitle": "Generate Criteria",
            "addCriteriaTitle": "Add Criteria",
            "editCriteriaTitle": "Edit Criteria",
            "selectProductLabel": "Select Product",
            "selectEpicLabel": "Select Epic",
            "selectStoryLabel": "Select User Story",
            "selectCriteriaLabel": "Select Acceptance Criteria",
            "criteriaDescriptionLabel": "Acceptance Criteria Description",
            "generateCriteriaButton": "Generate Criteria",
            "saveCriteria": "Save",
            "cancelCriteria": "Cancel",
            "saveCriteriaButton": "Save",
            "criteriaSavedSuccess": "Acceptance Criteria saved successfully.",
            "criteriaSaveError": "Error saving Acceptance Criteria.",
            "criteriaUpdatedSuccess": "Acceptance Criteria updated successfully.",
            "criteriaUpdateError": "Error updating Acceptance Criteria.",
            "criteriaDeletedSuccess": "Acceptance Criteria deleted successfully.",
            "criteriaDeleteError": "Error deleting Acceptance Criteria.",
            "errorGeneratingCriteria": "Error generating Acceptance Criteria:",
            deleteCriteria: "Delete",
            "selectProductSnackbar": "Please select a product before proceeding.",
            // Session
            "signOut": "Sign Out",
            "signUpTitle": "Sign Up",
            // Generate All Stories
            "promptGenerateEpicStories": "Given the context of development and product construction, including user details and purpose: '{{context}}'. Considering the epic description: '{{epicDescription}}'.\n\nGenerate a list of user stories that encompass the epic. Each story should follow the format: 'As a <role>, I want <action to be taken> so that <goal>. Each story should start with 'Title: ' followed by a brief title summarizing the main action in a few words, and then the story text.'",
            "generateEpicStoriesTitle": "Generate All",
            "promptDecomposeEpicStories": "Based on the original story: '{{originalStory}}', and considering the additional context provided: '{{additionalInfo}}', please generate more specific user stories that are directly related to the original functionality and context. Each story should start with 'Title: ' followed by a brief title summarizing the main action in a few words, and then the story text. Each story should be separated by line breaks, with no numbers or special characters before or after the story text.",
            // Footer
            linksTitle: "Links",
            aboutUsLinkTitle: "About Us",
            blogLinkTitle: "Blog",
            tutorialsLinkTitle: "Tutorials",
            faqsLinkTitle: "FAQs",
            contactLinkTitle: "Contact",
            resourcesTitle: "Resources",
            useCasesLinkTitle: "Use Cases",
            testimonialsLinkTitle: "Testimonials",
            legalTitle: "Legal",
            termsLinkTitle: "Terms and Conditions",
            privacyLinkTitle: "Privacy Policy",
            // About Us
            aboutUsTitle: "About Us",
            aboutUsWhoWeAre: "Who We Are",
            aboutUsWhoWeAreText: "At AI PO Helper, we are passionate about building digital products and enhancing productivity through agile methodology. Our mission is to create a tool that empowers every team to focus on what truly matters: delivering value to customers. Our journey began with a deep understanding of the challenges faced by product owners and agile teams, where the scarcity of time during planning, writing user stories, and effectively communicating them to development teams often leads to inefficiencies and misunderstandings.",
            aboutUsOurMission: "Our Mission",
            aboutUsOurMissionText: "We have experienced firsthand the crunch of tight schedules and the struggle to maintain clarity and understanding in our goals. That's why we believe in the transformative power of Artificial Intelligence (AI) to assist in these crucial tasks. AI PO Helper is designed to streamline the process of creating user stories, acceptance criteria, and epics, allowing teams to focus on high-value activities and reduce time spent on administrative tasks.",
            aboutUsWhyWeDoIt: "Why We Do It",
            aboutUsWhyWeDoItText: "The importance of clear communication and shared understanding in achieving successful project outcomes cannot be overstated. Miscommunication and ambiguity can lead to delays, rework, and ultimately, dissatisfied customers. By leveraging AI, our tool helps ensure that user stories are precise, comprehensive, and aligned with the team’s objectives, enhancing the overall efficiency and productivity of agile teams.",
            aboutUsOurVision: "Our Vision",
            aboutUsOurVisionText: "We envision a world where agile teams are not bogged down by the repetitive and time-consuming aspects of project management. Instead, they can dedicate their energy to innovative problem-solving and delivering exceptional products. AI PO Helper is committed to supporting this vision by providing a robust and intuitive platform that integrates seamlessly into your workflow, making agile practices more accessible and effective.",
            aboutUsOurApproach: "Our Approach",
            aboutUsUserCentricDesign: "User-Centric Design",
            aboutUsUserCentricDesignText: "We prioritize the needs of product owners and agile teams, ensuring that our tool is intuitive and easy to use.",
            aboutUsAIPoweredEfficiency: "AI-Powered Efficiency",
            aboutUsAIPoweredEfficiencyText: "Our AI prompts are designed to automate the creation of user stories and acceptance criteria, saving you valuable time and effort.",
            aboutUsContinuousImprovement: "Continuous Improvement",
            aboutUsContinuousImprovementText: "We are constantly evolving our platform based on user feedback and technological advancements to provide the best possible experience.",
            aboutUsJoinUs: "Join Us on Our Journey",
            aboutUsJoinUsText: "At AI PO Helper, we are committed to revolutionizing the way agile teams work. Join us on our journey to enhance productivity, improve communication, and deliver greater value to your customers. Together, we can make agile practices more efficient and enjoyable for everyone involved."
        }
    },
    es: {
        translation: {
            header: "AI PO Helper",
            contextLabel: "Haz una descripción  del producto: qué es, su propósito, a quién está dirigido, funcionalidades, tecnologías y cualquier información relevante. Mientras más contexto, mejor.",
            functionalityLabel: "Nombra la funcionalidad específica a desarrollar",
            generateButton: "Generar historia de usuario",
            generatingButton: "Generando...",
            decomposeButton: "Descomponer en más historias",
            copyButton: "Copiar",
            userStory: "Historia de usuario:",
            additionalInfoPlaceholder: "Introduce información adicional (opcional)",
            errorGeneratingStory: "Error al generar la historia de usuario:",
            generateUserStories: "Descomponer historia de usuario",
            generatingUserStories: "Descomponiendo historias de usuario...",
            decomposedStories: "Historias Descompuestas:",
            navBarTitle: "",
            additionalInfoHeader: "Información adicional de la historia",
            introText: "AI PO Helper es una plataforma que, por medio de la Inteligencia Artificial, te ayuda a optimizar tus tareas como Product Owner, ahorrándote tiempo y apoyándote en desarrollar productos de alta calidad.",
            describeProduct: "Describe tu producto",
            promptTemplate: "Dado el contexto de desarrollo y construcción del producto, incluyendo detalles del usuario y propósito: '{{context}}'.\n\nGenera una historia de usuario basada en la funcionalidad específica: '{{functionality}}', en el formato: 'Como <rol>, quiero <acción a realizar> para <objetivo>'. La historia debe comenzar con 'Título: ' seguido de un breve título que resuma la acción principal en pocas palabras, y luego el texto de la historia. Mantén el texto de la historia conciso, corto y directo (máximo 4 líneas de largo)",
            promptDecomposeTemplate: "Basado en la historia original: '{{originalStory}}', y considerando el contexto adicional proporcionado: '{{additionalInfo}}', por favor, genera historias de usuario más específicas que estén directamente relacionadas con la funcionalidad y el contexto originales. Cada historia debe comenzar con 'Título: ' seguido de un breve título que resuma la acción principal en pocas palabras, y luego el texto de la historia. Cada historia debe estar separada por saltos de línea, sin números ni caracteres especiales antes o después del texto de la historia.",
            homeTitle: "Optimiza tu productividad en el mundo de la agilidad con IA",
            titleGenerateStory: "User Stories",
            homeTitleGenerateStory: "Crea historias de usario fácilmente",
            homeDescriptionGenerateStory: "¡Mejora el entendimiento de tu equipo y consigue mejores resultados!",
            descriptionGenerateStory: "Concentrate en lo realmente importante y genera historias de usuario completas y de alta calidad",
            cardAcceptanceCriteriaTitle: "Criterios de aceptación",
            "promptAcceptanceCriteria": "Genera un listado enumerado de criterios de aceptación detallados, cada uno separado por un salto de línea. El enfoque de los criterios debe estar en lo que dice la siguiente historia de usuario proporcionada, que es Historia: {{storyDescription}} y teniendo en cuenta el contexto del producto, que es Contexto: {{context}}, asegúrate de cubrir todos los aspectos técnicos y funcionales. Responde solo con el listado.",
            generateAcceptanceCriButton: "Generar criterios de aceptación",
            copyToClipboardButton: "Copiar al portapapeles",
            landingStartButton: "¡Comienza gratis!",
            advantagesLanding: "Destacados",
            titleBlock1Landing: "Automatiza tus historias",
            descBlock1Landing: "Genera historias de usuario automáticamente.|Captura requisitos con facilidad y precisión.|Ideal para mantener la coherencia en la documentación de proyectos.",
            titleBlock2Landing: "Ahorra tiempo",
            "descBlock2Landing": "Reduce drásticamente el tiempo de planificación.|Libera recursos para desarrollo y diseño.|Automatiza procesos repetitivos y mejora la eficiencia.",
            titleBlock3Landing: "Crea mejores productos",
            "descBlock3Landing": "Eleva la calidad del producto final.|Asegura que las expectativas de usuarios y stakeholders sean claras y alcanzables.|Minimiza los riesgos de malentendidos y asegura un entendimiento común.",
            "generateEpicsButton": "Generar",
            "generateEpicsDesc": "Divide el desarrollo de tu producto en épicas para planificar su construcción de la mejor manera.",
            "titleGenerateAcceptanceCriteria": "Criterios de aceptación",
            "descriptionAcceptanceCriteria": "Genera los criterios de aceptación para tus historias de usuario.",
            "nameUserStoryLabel": "Ingresa la historia de usuario de la que quieres los criterios de aceptación",
            "generateAcceptanceCriteriaButton": "Generar Criterios de Aceptación",
            "workspaceMainTitle": "Selecciona una opción del menú",
            "menuButtonEpics": "Épicas",
            "menuButtonUserStories": "Historias de usuario",
            "menuButtonCriteria": "Criterios de aceptación",
            // Traducciones WelcomeCard
            "welcomeCardTitle": "AI PO Helper",
            "welcomeCardDescription": "Selecciona una opción del menú para comenzar, o usa los botones de abajo para crear nuevo contenido.",
            "welcomeCardDescriptionPrivate": "¡Bienvenido! Selecciona un producto en el menú para comenzar o crea uno en la sección dedicada para ello.",
            "welcomeCardDescriptionNoProductPrivate": "¡Bienvenido! Crea un nuevo producto para comenzar.",
            "welcomeEpicButton": "Épicas",
            "welcomeStoryButton": "Historias de Usuario",
            "welcomeCriteriaButton": "Criterios de Aceptación",
            "welcomeStartButton": "Comenzar",
            // ProductForm
            createProductTitle: "Producto",
            createProductDesc: "Un producto es un ítem o sistema desarrollado para satisfacer las necesidades del usuario, entregando valor a través del desarrollo iterativo e incremental",
            productNameLabel: "Nombre del producto",
            productDescriptionLabel: "Descripción del Producto",
            saveProduct: "Guardar",
            cancelProduct: "Cancelar",
            editProductTitle: "Editar Producto",
            addProductTitle: "Agregar Producto",
            searchProductTitle: "Buscar y Editar Producto",
            filterStatusLabel: "Filtrar por estado",
            searchByNameLabel: "Buscar por Nombre",
            productSavedSuccess: "Producto guardado correctamente",
            productSaveError: "Error al guardar el producto",
            deleteProduct: "Eliminar",
            productDeletedSuccess: "Producto eliminado correctamente",
            productDeletedError: "Error al eliminar el producto",
            productAddDescPlaceHolder: "Incluir al menos qué es el producto (app, web, etc.), su finalidad, usuarios principales, funcionalidades y aspectos técnicos. A mayor detalle, mejor calidad.",
            // Menú
            menuButtonProduct: "Productos",
            menuButtonAddProduct: "Agregar Producto",
            menuButtonEditProduct: "Editar Producto",
            // Épicas
            generateEpicsTitle: "Generar Épicas",
            addEpicTitle: "Añadir Épica",
            editEpicTitle: "Editar Épica",
            all: "Todas",
            active: "Activo",
            inactive: "Inactivo",
            in_development: "En Desarrollo",
            selectProductLabel: "Seleccionar Producto",
            // "epicsPrompt": `Genera una serie de épicas a partir de la descripción del producto proporcionada: "{{context}}". Para cada épica, proporciona un título en negrita y utilizando doble asterisco y sigue con una descripción en la línea siguiente. No enumeres las épicas. Cada épica y su descripción deben estar separadas de la siguiente por dos saltos de línea. Asegúrate de que la respuesta contenga solo los títulos y descripciones de las épicas, sin texto adicional o historias de usuario.`,
            "epicsPrompt": `Genera todas las épicas necesarias para desarrolla con éxito el producto a partir de la descripción del producto proporcionada: "{{context}}". Para cada épica proporciona un título no genérico y una descripción de no más de 3 líneas. Solo imprime el titulo generado de la épica y su descripción, no uses número de listado ni dos puntos (:) después del título de la épica. Respeta estrictamente el formato, en donde entre cada épica debe haber un doble salto de línea. Formato: Titulo \n Descripcion \n\n Titulo \n Descripcion \n\n ...`,
            epicTitleLabel: "Título de la Épica",
            epicDescriptionLabel: "Descripción de la Épica",
            saveEpic: "Guardar",
            cancelEpic: "Cancelar",
            epicSavedSuccess: "¡Épica guardada con éxito!",
            epicSaveError: "Error al guardar la épica.",
            epicUpdatedSuccess: "¡Épica actualizada con éxito!",
            epicUpdateError: "Error al actualizar la épica.",
            epicDeletedSuccess: "¡Épica eliminada con éxito!",
            epicDeleteError: "Error al eliminar la épica.",
            selectEpicLabel: "Seleccionar Épica",
            deleteEpic: "Eliminar",
            // Historias de usuario
            "generateStoryTitle": "Generar Historia",
            "addStoryTitle": "Agregar Historia",
            "editStoryTitle": "Editar Historia",
            "selectStoryLabel": "Seleccionar Historia",
            "storyTitleLabel": "Título de la Historia",
            "storyDescriptionLabel": "Descripción de la Historia",
            "generateStoriesButton": "Generar Historia de Usuario",
            "saveStory": "Guardar Historia",
            "cancelStory": "Cancelar",
            "deleteStory": "Eliminar Historia",
            "storySavedSuccess": "Historia guardada con éxito",
            "storySaveError": "Error al guardar la historia",
            "storyUpdatedSuccess": "Historia actualizada con éxito",
            "storyUpdateError": "Error al actualizar la historia",
            "storyDeletedSuccess": "Historia eliminada con éxito",
            "storyDeleteError": "Error al eliminar la historia",
            "saveStoryButton": "Guardar Historia",
            "storiesPrompt": "Genera una serie de historias de usuario basadas en el contexto de la épica proporcionada: '{{context}}'. Para cada historia, proporciona un título y una descripción. No enumeres las historias. Cada historia y su descripción deben estar separadas de la siguiente por dos saltos de línea.",
            // Criterios de aceptación
            "generateCriteriaTitle": "Generar Criterios",
            "addCriteriaTitle": "Agregar Criterio",
            "editCriteriaTitle": "Editar Criterios",
            "selectCriteriaLabel": "Seleccionar Criterios de Aceptación",
            "criteriaDescriptionLabel": "Descripción de los Criterios de Aceptación",
            "generateCriteriaButton": "Generar",
            "saveCriteria": "Guardar",
            "cancelCriteria": "Cancelar",
            "saveCriteriaButton": "Guardar",
            "criteriaSavedSuccess": "Criterios de Aceptación guardados con éxito.",
            "criteriaSaveError": "Error al guardar los Criterios de Aceptación.",
            "criteriaUpdatedSuccess": "Criterios de Aceptación actualizados con éxito.",
            "criteriaUpdateError": "Error al actualizar los Criterios de Aceptación.",
            "criteriaDeletedSuccess": "Criterios de Aceptación eliminados con éxito.",
            "criteriaDeleteError": "Error al eliminar los Criterios de Aceptación.",
            "errorGeneratingCriteria": "Error al generar los Criterios de Aceptación:",
            deleteCriteria: "Eliminar",
            // Verificar selección de producto
            "selectProductSnackbar": "Por favor, selecciona un producto antes de proceder.",
            // Sesión
            "signOut": "Sign Out",
            "signUpTitle": "Inscríbete",
            // Generar todas las historias
            // "promptGenerateEpicStories": "Dado el contexto de desarrollo y construcción del producto, incluyendo detalles del usuario y propósito: '{{context}}'. Considerando la descripción de la épica: '{{epicDescription}}'.\n\nGenera una lista de historias de usuario que abarquen la épica. Cada historia debe seguir el formato: 'Como <rol>, quiero <acción a tomar> para <objetivo>. Cada historia debe comenzar con 'Título: ' seguido de un breve título que resuma la acción principal en pocas palabras, y luego el texto de la historia.'",
            "promptGenerateEpicStories": "Dado el contexto de desarrollo y construcción del producto, incluyendo detalles del usuario y propósito: '{{context}}'. Considerando la descripción de la épica: '{{epicDescription}}'.\n\nGenera una lista de historias de usuario que abarquen la épica. Cada historia debe seguir el formato: 'Como <rol>, quiero <acción a tomar> para <objetivo>. Cada historia debe comenzar con 'Título: ' seguido de un breve título que resuma la acción principal en pocas palabras, y luego el texto de la historia. [formato:'Título: <titulo_generado> Como <rol>, quiero <acción a tomar> para <objetivo>']",
            "generateEpicStoriesTitle": "Generar todas",
            "promptDecomposeEpicStories": "Basado en la historia original: '{{originalStory}}', y considerando el contexto adicional proporcionado: '{{additionalInfo}}', por favor genera historias de usuario más específicas que estén directamente relacionadas con la funcionalidad y el contexto original. Cada historia debe comenzar con 'Título: ' seguido de un breve título que resuma la acción principal en pocas palabras, y luego el texto de la historia. Cada historia debe estar separada por saltos de línea, sin números o caracteres especiales antes o después del texto de la historia.",
            // Footer
            linksTitle: "Enlaces",
            aboutUsLinkTitle: "Acerca de Nosotros",
            blogLinkTitle: "Blog",
            tutorialsLinkTitle: "Tutoriales",
            faqsLinkTitle: "FAQs",
            contactLinkTitle: "Contacto",
            resourcesTitle: "Recursos",
            useCasesLinkTitle: "Casos de Uso",
            testimonialsLinkTitle: "Testimonios",
            legalTitle: "Legal",
            termsLinkTitle: "Términos y Condiciones",
            privacyLinkTitle: "Política de Privacidad",
            // AboutUs
            aboutUsTitle: "Acerca de Nosotros",
            aboutUsWhoWeAre: "Quiénes Somos",
            aboutUsWhoWeAreText: "En AI PO Helper, somos apasionados por la construcción de productos digitales y por la productividad a través de la metodología ágil. Nuestra misión es crear una herramienta que empodere a cada equipo para que se concentre en lo que realmente importa: entregar valor a los clientes. Nuestro viaje comenzó con una profunda comprensión de los desafíos que enfrentan los product owners y los equipos ágiles, donde la escasez de tiempo durante la planificación, la escritura de historias de usuario y la comunicación efectiva con los equipos de desarrollo a menudo conduce a ineficiencias y malentendidos.",
            aboutUsOurMission: "Nuestra Misión",
            aboutUsOurMissionText: "Hemos experimentado de primera mano la presión de los horarios ajustados y la lucha por mantener la claridad y la comprensión en nuestros objetivos. Por eso creemos en el poder transformador de la Inteligencia Artificial (IA) para ayudar en estas tareas cruciales. AI PO Helper está diseñado para optimizar el proceso de creación de historias de usuario, criterios de aceptación y épicas, permitiendo a los equipos centrarse en actividades de alto valor y reducir el tiempo dedicado a tareas administrativas.",
            aboutUsWhyWeDoIt: "Por Qué Lo Hacemos",
            aboutUsWhyWeDoItText: "La importancia de una comunicación clara y un entendimiento compartido para lograr resultados exitosos en los proyectos no puede ser subestimada. La mala comunicación y la ambigüedad pueden llevar a retrasos, rehacer trabajo y, en última instancia, a clientes insatisfechos. Al aprovechar la IA, nuestra herramienta ayuda a garantizar que las historias de usuario sean precisas, completas y alineadas con los objetivos del equipo, mejorando la eficiencia y productividad general de los equipos ágiles.",
            aboutUsOurVision: "Nuestra Visión",
            aboutUsOurVisionText: "Imaginamos un mundo donde los equipos ágiles no estén agobiados por los aspectos repetitivos y que consumen tiempo de la gestión de proyectos. En su lugar, pueden dedicar su energía a resolver problemas de manera innovadora y a entregar productos excepcionales. AI PO Helper está comprometido con esta visión al proporcionar una plataforma robusta e intuitiva que se integra perfectamente en su flujo de trabajo, haciendo que las prácticas ágiles sean más accesibles y efectivas.",
            aboutUsOurApproach: "Nuestro Enfoque",
            aboutUsUserCentricDesign: "Diseño Centrado en el Usuario",
            aboutUsUserCentricDesignText: "Priorizamos las necesidades de los product owners y los equipos ágiles, asegurando que nuestra herramienta sea intuitiva y fácil de usar.",
            aboutUsAIPoweredEfficiency: "Eficiencia Potenciada por IA",
            aboutUsAIPoweredEfficiencyText: "Nuestros prompts de IA están diseñados para automatizar la creación de historias de usuario y criterios de aceptación, ahorrándote tiempo y esfuerzo valiosos.",
            aboutUsContinuousImprovement: "Mejora Continua",
            aboutUsContinuousImprovementText: "Estamos constantemente evolucionando nuestra plataforma en base a la retroalimentación de los usuarios y los avances tecnológicos para ofrecer la mejor experiencia posible.",
            aboutUsJoinUs: "Únete a Nuestro Viaje",
            aboutUsJoinUsText: "En AI PO Helper, estamos comprometidos a revolucionar la forma en que trabajan los equipos ágiles. Únete a nosotros en nuestro viaje para mejorar la productividad, la comunicación y entregar mayor valor a tus clientes. Juntos, podemos hacer que las prácticas ágiles sean más eficientes y agradables para todos los involucrados.",

        }
    }
};

i18n
    .use(initReactI18next) // Pasa la instancia de i18next a react-i18next.
    .use(LanguageDetector) // Detecta el idioma del navegador automáticamente.
    .init({
        resources,
        fallbackLng: 'en', // Lenguaje de respaldo si no se encuentra el lenguaje actual.
        detection: {
            order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
            caches: ['localStorage', 'cookie']
        },
        interpolation: {
            escapeValue: false, // No escapar valores.
        },
    });

export default i18n;
