import React, { useState, useEffect } from 'react';
import { TextField, Button, Grid, Typography, Container, Paper, Box, Select, InputLabel, FormControl, MenuItem, Pagination } from '@mui/material';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useProduct } from '../../context/ProductContext';

const ProductSearch = ({ onSelectProduct }) => {
    const { fetchProducts } = useProduct();
    const [filterStatus, setFilterStatus] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const productsPerPage = 6;
    const { t } = useTranslation();

    const productStatus = [
        { value: 'active', label: t('active') },
        { value: 'in_development', label: t('in_development') },
        { value: 'inactive', label: t('inactive') },
    ];

    useEffect(() => {
        const fetchProductsLocal = async () => {
            try {
                const response = await axios.get('/api/products');
                setProducts(response.data);
                setFilteredProducts(response.data);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        fetchProductsLocal();
    }, []);

    useEffect(() => {
        const filtered = products.filter(product =>
            (filterStatus ? product.status === filterStatus : true) &&
            (searchQuery ? product.name.toLowerCase().includes(searchQuery.toLowerCase()) : true)
        );
        setFilteredProducts(filtered);
    }, [filterStatus, searchQuery, products]);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);

    return (
        <Container maxWidth="sm">
            <Paper elevation={3} style={{ padding: '32px 16px' }}>
                <Typography variant="h6" gutterBottom style={{ marginBottom: '24px' }}>
                    {t('searchProductTitle')}
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel id="filter-status-label">{t('filterStatusLabel')}</InputLabel>
                            <Select
                                labelId="filter-status-label"
                                value={filterStatus}
                                onChange={(e) => setFilterStatus(e.target.value)}
                                label={t('filterStatusLabel')}
                            >
                                <MenuItem value=""><em>{t('all')}</em></MenuItem>
                                {productStatus.map(option => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label={t('searchByNameLabel')}
                            variant="outlined"
                            fullWidth
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {currentProducts.map(product => (
                            <Button
                                key={product.product_id}
                                variant="outlined"
                                fullWidth
                                style={{ margin: '4px 0' }}
                                onClick={() => onSelectProduct(product)}
                            >
                                {product.name}
                            </Button>
                        ))}
                    </Grid>
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="center" mt={2}>
                            <Pagination
                                count={Math.ceil(filteredProducts.length / productsPerPage)}
                                page={currentPage}
                                onChange={handlePageChange}
                                color="primary"
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
};

export default ProductSearch;
