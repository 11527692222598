import React, { useState, useEffect } from 'react';
import { TextField, Button, MenuItem, Grid, Typography, Container, Paper, Box, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useProduct } from '../../context/ProductContext';

const ProductForm = ({ product, onSave, onCancel, onDelete }) => {
    const { fetchProducts } = useProduct();
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [status, setStatus] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const { t } = useTranslation();

    const productStatus = [
        { value: 'active', label: t('active') },
        { value: 'in_development', label: t('in_development') },
        { value: 'inactive', label: t('inactive') },
    ];

    useEffect(() => {
        if (product) {
            setTitle(product.name);
            setDescription(product.description);
            setStatus(product.status);
        } else {
            setTitle('');
            setDescription('');
            setStatus('');
        }
    }, [product]);

    const handleSave = async () => {
        setIsSaving(true);
        try {
            const updatedProduct = { ...product, name: title, description, status };
            if (product && product.product_id) {
                await axios.put(`/api/products/${product.product_id}`, {
                    name: title,
                    description,
                    status,
                });
            } else {
                await axios.post('/api/products', {
                    name: title,
                    description,
                    status,
                });
            }
            onSave(updatedProduct, true);
            fetchProducts(); // Actualiza la lista de productos
            setTitle('');
            setDescription('');
            setStatus('');
        } catch (error) {
            console.error('Error saving product: ', error);
            onSave(null, false);
        } finally {
            setIsSaving(false);
        }
    };

    const handleDelete = async () => {
        if (!product || !product.product_id) return;

        setIsDeleting(true);
        try {
            await axios.delete(`/api/products/${product.product_id}`);
            onDelete(product.product_id, true);
            fetchProducts(); // Actualiza la lista de productos
        } catch (error) {
            console.error('Error deleting product:', error);
            onDelete(null, false);
        } finally {
            setIsDeleting(false);
        }
    };

    const handleCancel = () => {
        onCancel();
        setTitle('');
        setDescription('');
        setStatus('');
    };

    return (
        <Container maxWidth="sm">
            <Paper elevation={3} style={{ padding: '32px 16px' }}>
                <Typography variant="h6" gutterBottom style={{ marginBottom: '24px' }}>
                    {product ? t('editProductTitle') : t('addProductTitle')}
                </Typography>
                {/*                 <Typography variant="body1" gutterBottom style={{ marginBottom: '24px', fontSize: '0.9rem' }}>
                    {product ? t('editProductIntro') : t('addProductIntro')}
                </Typography> */}

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            label={t('productNameLabel')}
                            variant="outlined"
                            fullWidth
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label={t('productDescriptionLabel')}
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={4}
                            placeholder={t('productAddDescPlaceHolder')}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            select
                            label={t('filterStatusLabel')}
                            variant="outlined"
                            fullWidth
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                        >
                            {productStatus.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', gap: '8px' }}>
                        <Box style={{ display: 'flex', gap: '8px', flex: 1 }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSave}
                                disabled={isSaving}
                                style={{ flex: '1 1 auto', maxWidth: '150px' }}
                            >
                                {isSaving ? <CircularProgress size={24} /> : t('saveProduct')}
                            </Button>
                            {product && product.product_id && (
                                <Button
                                    variant="contained"
                                    color="error"
                                    onClick={handleDelete}
                                    disabled={isDeleting}
                                    style={{ flex: '1 1 auto', maxWidth: '150px' }}
                                >
                                    {isDeleting ? <CircularProgress size={24} /> : t('deleteProduct')}
                                </Button>
                            )}
                        </Box>
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={handleCancel}
                            style={{ maxWidth: '150px' }}
                        >
                            {t('cancelProduct')}
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
};

export default ProductForm;
