import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Button, Box, Container } from '@mui/material';
import '../css/WelcomeCard.css';

const WelcomeCard = ({ setFormType }) => {
    const { t } = useTranslation();

    return (
        <Container maxWidth="sm">
            <Box className="welcome-card">
                <Typography variant="h5" className="welcome-card-title" gutterBottom>
                    {t('welcomeCardTitle')}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    {t('welcomeCardDescription')}
                </Typography>
                <img src='img/welcomeCard.jpg' alt="Welcome" className="welcome-card-image" />
                <Box className="welcome-card-buttons">
                    <Button variant="contained" color="primary" onClick={() => setFormType('EpicForm')}>
                        {t('welcomeEpicButton')}
                    </Button>
                    <Button variant="contained" color="primary" onClick={() => setFormType('StoryForm')}>
                        {t('welcomeStoryButton')}
                    </Button>
                    <Button variant="contained" color="primary" onClick={() => setFormType('AcceptanceCriteriaForm')}>
                        {t('welcomeCriteriaButton')}
                    </Button>
                </Box>
            </Box>
        </Container>
    );
};

export default WelcomeCard;
