import React, { useState, useEffect } from 'react';
import { TextField, Button, MenuItem, Grid, Typography, Container, Paper, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import CustomSnackbar from '../messages/CustomSnackbar';
import { useProduct } from '../../context/ProductContext';

const AddStory = () => {
    const { selectedProduct } = useProduct();
    const [epic, setEpic] = useState('');
    const [epics, setEpics] = useState([]);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarVariant, setSnackbarVariant] = useState('success');
    const { t } = useTranslation();

    useEffect(() => {
        if (selectedProduct) {
            const fetchEpics = async () => {
                try {
                    const response = await axios.get(`/api/epics/product/${selectedProduct}`);
                    setEpics(response.data);
                } catch (error) {
                    console.error('Error fetching epics:', error);
                }
            };
            fetchEpics();
        }
    }, [selectedProduct]);

    const handleSaveStory = async () => {
        setIsSaving(true);
        setSnackbarMessage('');
        try {
            await axios.post('/api/stories', {
                name: title,
                description,
                epic_id: epic
            });
            setTitle('');
            setDescription('');
            setSnackbarMessage(t('storySavedSuccess')); // Mensaje de confirmación
            setSnackbarVariant('success');
        } catch (error) {
            console.error('Error saving story:', error);
            setSnackbarMessage(t('storySaveError')); // Mensaje de error
            setSnackbarVariant('error');
        } finally {
            setIsSaving(false);
            setSnackbarOpen(true);
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <Container maxWidth="sm">
            <Paper elevation={3} style={{ padding: '32px 16px' }}>
                <Typography variant="h6" gutterBottom>
                    {t('addStoryTitle')}
                </Typography>
                <Grid container spacing={2}>
                    {/*                     <Grid item xs={12}>
                        <Typography variant="body1" gutterBottom>
                            {t('selectedProduct')}: {selectedProduct ? selectedProduct : t('noProductSelected')}
                        </Typography>
                    </Grid> */}
                    <Grid item xs={12}>
                        <TextField
                            select
                            label={t('selectEpicLabel')}
                            variant="outlined"
                            fullWidth
                            value={epic}
                            onChange={(e) => setEpic(e.target.value)}
                            disabled={!selectedProduct}
                            id="epic"
                        >
                            {epics.map(e => (
                                <MenuItem key={e.epic_id} value={e.epic_id}>
                                    {e.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label={t('storyTitleLabel')}
                            variant="outlined"
                            fullWidth
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            id="storyTitle"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label={t('storyDescriptionLabel')}
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={4}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            id="storyDescription"
                        />
                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', gap: '8px' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSaveStory}
                            disabled={isSaving || !selectedProduct || !epic}
                        >
                            {isSaving ? <CircularProgress size={24} /> : t('saveStory')}
                        </Button>
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() => {
                                setTitle('');
                                setDescription('');
                            }}
                        >
                            {t('cancelStory')}
                        </Button>
                    </Grid>
                </Grid>
                <CustomSnackbar
                    open={snackbarOpen}
                    message={snackbarMessage}
                    onClose={handleSnackbarClose}
                    variant={snackbarVariant}
                />
            </Paper>
        </Container>
    );
};

export default AddStory;
