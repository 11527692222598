import React, { useState, useEffect } from 'react';
import { Container, Box, TextField, Button, Grid, Typography, Paper, CircularProgress, useMediaQuery, Pagination } from '@mui/material';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import CustomSnackbar from '../messages/CustomSnackbar';
import { useProduct } from '../../context/ProductContext';

const EditEpic = () => {
    const { selectedProduct } = useProduct();
    const [selectedEpic, setSelectedEpic] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [epics, setEpics] = useState([]);
    const [filteredEpics, setFilteredEpics] = useState([]);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarVariant, setSnackbarVariant] = useState('success');
    const [currentPage, setCurrentPage] = useState(1);
    const epicsPerPage = 6;
    const { t } = useTranslation();
    const isMobile = useMediaQuery('(max-width:600px)');

    const fetchEpics = async () => {
        if (selectedProduct) {
            try {
                const response = await axios.get(`/api/epics/product/${selectedProduct}`);
                setEpics(response.data);
                setFilteredEpics(response.data);
            } catch (error) {
                console.error('Error fetching epics:', error);
            }
        }
    };

    useEffect(() => {
        fetchEpics();
    }, [selectedProduct]);

    useEffect(() => {
        const filtered = epics.filter(epic =>
            searchQuery ? epic.name.toLowerCase().includes(searchQuery.toLowerCase()) : true
        );
        setFilteredEpics(filtered);
    }, [searchQuery, epics]);

    useEffect(() => {
        const selectedEpicData = epics.find(e => e.epic_id === selectedEpic?.epic_id);
        if (selectedEpicData) {
            setTitle(selectedEpicData.name);
            setDescription(selectedEpicData.description);
        } else {
            setTitle('');
            setDescription('');
        }
    }, [selectedEpic, epics]);

    const handleSelectEpic = (epic) => {
        setSelectedEpic(epic);
    };

    const handleSaveEpic = async () => {
        setIsSaving(true);
        try {
            const updatedEpic = { ...selectedEpic, name: title, description };
            if (selectedEpic && selectedEpic.epic_id) {
                await axios.put(`/api/epics/${selectedEpic.epic_id}`, {
                    name: title,
                    description,
                });
            } else {
                await axios.post('/api/epics', {
                    name: title,
                    description,
                    product_id: selectedProduct,
                });
            }
            handleSaveFeedback(true);
        } catch (error) {
            console.error('Error saving epic: ', error);
            handleSaveFeedback(false);
        } finally {
            setIsSaving(false);
        }
    };

    const handleDeleteEpic = async () => {
        if (!selectedEpic || !selectedEpic.epic_id) return;

        setIsDeleting(true);
        try {
            await axios.delete(`/api/epics/${selectedEpic.epic_id}`);
            handleDeleteFeedback(true);
        } catch (error) {
            console.error('Error deleting epic:', error);
            handleDeleteFeedback(false);
        } finally {
            setIsDeleting(false);
        }
    };

    const handleSaveFeedback = async (success) => {
        setSnackbarMessage(success ? t('epicSavedSuccess') : t('epicSaveError'));
        setSnackbarVariant(success ? 'success' : 'error');
        setSnackbarOpen(true);
        if (success) {
            await fetchEpics();
            handleCancel(); // Volver al listado después de guardar
        }
    };

    const handleDeleteFeedback = async (success) => {
        setSnackbarMessage(success ? t('epicDeletedSuccess') : t('epicDeleteError'));
        setSnackbarVariant(success ? 'success' : 'error');
        setSnackbarOpen(true);
        if (success) {
            await fetchEpics();
            handleCancel(); // Volver al listado después de borrar
        }
    };

    const handleCancel = () => {
        setSelectedEpic(null);
        setTitle('');
        setDescription('');
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const indexOfLastEpic = currentPage * epicsPerPage;
    const indexOfFirstEpic = indexOfLastEpic - epicsPerPage;
    const currentEpics = filteredEpics.slice(indexOfFirstEpic, indexOfLastEpic);

    return (
        <Container>
            <Box mt={2}>
                {!selectedEpic && (
                    <Container maxWidth="sm">
                        <Paper elevation={3} style={{ padding: '32px 16px' }}>
                            <Typography variant="h6" gutterBottom>
                                {t('editEpicTitle')}
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        label={t('searchByNameLabel')}
                                        variant="outlined"
                                        fullWidth
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {currentEpics.map(epic => (
                                        <Button
                                            key={epic.epic_id}
                                            variant="outlined"
                                            fullWidth
                                            style={{ margin: '4px 0' }}
                                            onClick={() => handleSelectEpic(epic)}
                                        >
                                            {epic.name}
                                        </Button>
                                    ))}
                                </Grid>
                                <Grid item xs={12}>
                                    <Box display="flex" justifyContent="center" mt={2}>
                                        <Pagination
                                            count={Math.ceil(filteredEpics.length / epicsPerPage)}
                                            page={currentPage}
                                            onChange={handlePageChange}
                                            color="primary"
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Container>
                )}
                {selectedEpic && (
                    <Paper elevation={3} style={{ padding: '32px 16px' }}>
                        <Typography variant="h6" gutterBottom style={{ marginBottom: '24px' }}>
                            {t('editEpicTitle')}
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    label={t('epicTitleLabel')}
                                    variant="outlined"
                                    fullWidth
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label={t('epicDescriptionLabel')}
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={4}
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', gap: '8px' }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSaveEpic}
                                        disabled={isSaving}
                                    >
                                        {isSaving ? <CircularProgress size={24} /> : t('saveEpic')}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        onClick={handleDeleteEpic}
                                        disabled={isDeleting}
                                    >
                                        {isDeleting ? <CircularProgress size={24} /> : t('deleteEpic')}
                                    </Button>
                                </div>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={handleCancel}
                                >
                                    {t('cancelEpic')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                )}
            </Box>
            <CustomSnackbar
                open={snackbarOpen}
                message={snackbarMessage}
                onClose={handleSnackbarClose}
                variant={snackbarVariant}
            />
        </Container>
    );
};

export default EditEpic;
