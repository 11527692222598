import React from 'react';
import { Card, CardContent, Typography, IconButton, Box, List, ListItem } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SaveIcon from '@mui/icons-material/Save';
import { useTranslation } from 'react-i18next';
import '../../css/StoryCard.css';

const CriteriaCard = ({ criteria, onSave, onCopy }) => {
    const { t } = useTranslation();

    // Divide el texto de los criterios en una lista basada en los números de la lista, incluyendo números de dos dígitos y más
    const criteriaList = criteria.split(/\d+\.\s/).filter(item => item.trim() !== '').map((item, index) => (
        <ListItem key={index}>
            <Typography>{index + 1}. {item.trim()}</Typography>
        </ListItem>
    ));

    return (
        <Card className="customStoryCard storyCard">
            <CardContent>
                <Box className="storyCardBox">
                    <Box className="storyCardText">
                        <List>
                            {criteriaList}
                        </List>
                    </Box>
                    <Box display="flex" flexDirection="column" alignItems="flex-end" mt={2}>
                        <IconButton onClick={() => onCopy(criteria)} color="primary" title={t('copyToClipboardButton')}>
                            <ContentCopyIcon />
                        </IconButton>
                        <IconButton onClick={() => onSave(criteria)} color="primary" title={t('saveCriteriaButton')}>
                            <SaveIcon />
                        </IconButton>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
};

export default CriteriaCard;
