import React, { useState, useEffect } from 'react';
import { Container, Box, TextField, Button, Grid, Typography, Paper, CircularProgress, useMediaQuery, Pagination, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import CustomSnackbar from '../messages/CustomSnackbar';
import { useProduct } from '../../context/ProductContext';

const EditStory = () => {
    const { selectedProduct } = useProduct();
    const [selectedEpic, setSelectedEpic] = useState('');
    const [epics, setEpics] = useState([]);
    const [selectedStory, setSelectedStory] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [stories, setStories] = useState([]);
    const [filteredStories, setFilteredStories] = useState([]);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarVariant, setSnackbarVariant] = useState('success');
    const [currentPage, setCurrentPage] = useState(1);
    const storiesPerPage = 6;
    const { t } = useTranslation();
    const isMobile = useMediaQuery('(max-width:600px)');

    const fetchEpics = async () => {
        if (selectedProduct) {
            try {
                const response = await axios.get(`/api/epics/product/${selectedProduct}`);
                setEpics(response.data);
            } catch (error) {
                console.error('Error fetching epics:', error);
            }
        }
    };

    const fetchStories = async (epicId) => {
        if (epicId) {
            try {
                const response = await axios.get(`/api/stories/epic/${epicId}`);
                setStories(response.data);
                setFilteredStories(response.data);
            } catch (error) {
                console.error('Error fetching stories:', error);
            }
        }
    };

    useEffect(() => {
        fetchEpics();
    }, [selectedProduct]);

    useEffect(() => {
        fetchStories(selectedEpic);
    }, [selectedEpic]);

    useEffect(() => {
        const filtered = stories.filter(story =>
            searchQuery ? story.name.toLowerCase().includes(searchQuery.toLowerCase()) : true
        );
        setFilteredStories(filtered);
    }, [searchQuery, stories]);

    useEffect(() => {
        const selectedStoryData = stories.find(s => s.user_story_id === selectedStory?.user_story_id);
        if (selectedStoryData) {
            setTitle(selectedStoryData.name);
            setDescription(selectedStoryData.description);
        } else {
            setTitle('');
            setDescription('');
        }
    }, [selectedStory, stories]);

    const handleSelectStory = (story) => {
        setSelectedStory(story);
    };

    const handleSaveStory = async () => {
        setIsSaving(true);
        try {
            const updatedStory = { ...selectedStory, name: title, description };
            if (selectedStory && selectedStory.user_story_id) {
                await axios.put(`/api/stories/${selectedStory.user_story_id}`, {
                    name: title,
                    description,
                });
            } else {
                await axios.post('/api/stories', {
                    name: title,
                    description,
                    epic_id: selectedEpic,
                });
            }
            handleSaveFeedback(true, updatedStory);
        } catch (error) {
            console.error('Error saving story: ', error);
            handleSaveFeedback(false);
        } finally {
            setIsSaving(false);
        }
    };

    const handleDeleteStory = async () => {
        if (!selectedStory || !selectedStory.user_story_id) return;

        setIsDeleting(true);
        try {
            await axios.delete(`/api/stories/${selectedStory.user_story_id}`);
            handleDeleteFeedback(true);
        } catch (error) {
            console.error('Error deleting story:', error);
            handleDeleteFeedback(false);
        } finally {
            setIsDeleting(false);
        }
    };

    const handleSaveFeedback = async (success, updatedStory = null) => {
        setSnackbarMessage(success ? t('storySavedSuccess') : t('storySaveError'));
        setSnackbarVariant(success ? 'success' : 'error');
        setSnackbarOpen(true);
        if (success) {
            await fetchStories(selectedEpic);
            handleCancel(); // Volver al listado después de guardar
        }
    };

    const handleDeleteFeedback = async (success) => {
        setSnackbarMessage(success ? t('storyDeletedSuccess') : t('storyDeleteError'));
        setSnackbarVariant(success ? 'success' : 'error');
        setSnackbarOpen(true);
        if (success) {
            await fetchStories(selectedEpic);
            handleCancel(); // Volver al listado después de borrar
        }
    };

    const handleCancel = () => {
        setSelectedStory(null);
        setTitle('');
        setDescription('');
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const indexOfLastStory = currentPage * storiesPerPage;
    const indexOfFirstStory = indexOfLastStory - storiesPerPage;
    const currentStories = filteredStories.slice(indexOfFirstStory, indexOfLastStory);

    return (
        <Container>
            <Box mt={2}>
                {!selectedStory && (
                    <Container maxWidth="sm">
                        <Paper elevation={3} style={{ padding: '32px 16px' }}>
                            <Typography variant="h6" gutterBottom>
                                {t('editStoryTitle')}
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        select
                                        label={t('selectEpicLabel')}
                                        variant="outlined"
                                        fullWidth
                                        value={selectedEpic}
                                        onChange={(e) => setSelectedEpic(e.target.value)}
                                        disabled={!selectedProduct}
                                    >
                                        {epics.map(e => (
                                            <MenuItem key={e.epic_id} value={e.epic_id}>
                                                {e.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                {selectedEpic && (
                                    <>
                                        <Grid item xs={12}>
                                            <TextField
                                                label={t('searchByNameLabel')}
                                                variant="outlined"
                                                fullWidth
                                                value={searchQuery}
                                                onChange={(e) => setSearchQuery(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            {currentStories.map(story => (
                                                <Button
                                                    key={story.user_story_id}
                                                    variant="outlined"
                                                    fullWidth
                                                    style={{ margin: '4px 0' }}
                                                    onClick={() => handleSelectStory(story)}
                                                >
                                                    {story.name}
                                                </Button>
                                            ))}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box display="flex" justifyContent="center" mt={2}>
                                                <Pagination
                                                    count={Math.ceil(filteredStories.length / storiesPerPage)}
                                                    page={currentPage}
                                                    onChange={handlePageChange}
                                                    color="primary"
                                                />
                                            </Box>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </Paper>
                    </Container>
                )}
                {selectedStory && (
                    <Paper elevation={3} style={{ padding: '32px 16px' }}>
                        <Typography variant="h6" gutterBottom style={{ marginBottom: '24px' }}>
                            {t('editStoryTitle')}
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    label={t('storyTitleLabel')}
                                    variant="outlined"
                                    fullWidth
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label={t('storyDescriptionLabel')}
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={4}
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', gap: '8px' }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSaveStory}
                                        disabled={isSaving}
                                    >
                                        {isSaving ? <CircularProgress size={24} /> : t('saveStory')}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        onClick={handleDeleteStory}
                                        disabled={isDeleting}
                                    >
                                        {isDeleting ? <CircularProgress size={24} /> : t('deleteStory')}
                                    </Button>
                                </div>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={handleCancel}
                                >
                                    {t('cancelStory')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                )}
            </Box>
            <CustomSnackbar
                open={snackbarOpen}
                message={snackbarMessage}
                onClose={handleSnackbarClose}
                variant={snackbarVariant}
            />
        </Container>
    );
};

export default EditStory;
