import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Drawer, List, CssBaseline, Toolbar, Typography, IconButton,
    Container, Grid, Paper, ListItemIcon, ListItem, ListItemText, Divider, Box, useMediaQuery, Modal, Tooltip, Snackbar, Button
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import HomeIcon from '@mui/icons-material/Home';
import LayersIcon from '@mui/icons-material/Layers';
import DescriptionIcon from '@mui/icons-material/Description';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EpicManager from '../components/epics/EpicManager';
import StoryManager from '../components/userStories/StoryManager';
import ProductForm from '../components/products/ProductManager';
import CriteriaManager from '../components/acceptanceCriteria2/CriteriaManager';
import WelcomeCard from '../components/welcome/WelcomeCard';
import ProductSelectorWrapper from '../components/ProductSelectorWrapper';
import { useProduct } from '../context/ProductContext';
import '../css/DeskPage.css';
import BuildIcon from '@mui/icons-material/Build';
import SelectAllIcon from '@mui/icons-material/SelectAll';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const mainListItems = (setFormType, t, open, setModalOpen, isProductSelected, hasActiveProducts, showSnackbar, handleMenuItemClick, isSmallScreen) => (
    <div>
        {open ? (
            <Box sx={{ marginRight: isSmallScreen ? '16px' : '0px' }}>
                <ProductSelectorWrapper isDrawerOpen={open} />
            </Box>
        ) : (
            <Tooltip title={t('selectProductLabel')}>
                <IconButton onClick={() => setModalOpen(true)} sx={{ margin: '0 auto', display: 'block' }}>
                    <SelectAllIcon />
                </IconButton>
            </Tooltip>
        )}
        <ListItem onClick={() => { setFormType('WelcomeCard'); handleMenuItemClick(); }} component="div" className="list-item">
            <ListItemIcon>
                <HomeIcon />
            </ListItemIcon>
            <ListItemText primary='General' />
        </ListItem>
        <ListItem onClick={() => { setFormType('ProductForm'); handleMenuItemClick(); }} component="div" className="list-item">
            <ListItemIcon>
                <BuildIcon />
            </ListItemIcon>
            <ListItemText primary={t('menuButtonProduct')} />
        </ListItem>
        <ListItem onClick={() => { if (isProductSelected() && hasActiveProducts()) { setFormType('EpicManager'); handleMenuItemClick(); } else { showSnackbar(); } }} component="div" className="list-item">
            <ListItemIcon>
                <LayersIcon />
            </ListItemIcon>
            <ListItemText primary={t('menuButtonEpics')} />
        </ListItem>
        <ListItem onClick={() => { if (isProductSelected() && hasActiveProducts()) { setFormType('StoryManager'); handleMenuItemClick(); } else { showSnackbar(); } }} component="div" className="list-item">
            <ListItemIcon>
                <DescriptionIcon />
            </ListItemIcon>
            <ListItemText primary={t('menuButtonUserStories')} />
        </ListItem>
        <ListItem onClick={() => { if (isProductSelected() && hasActiveProducts()) { setFormType('CriteriaManager'); handleMenuItemClick(); } else { showSnackbar(); } }} component="div" className="list-item">
            <ListItemIcon>
                <CheckCircleIcon />
            </ListItemIcon>
            <ListItemText primary={t('menuButtonCriteria')} className="list-item-text" />
        </ListItem>
    </div>
);

const secondaryListItems = (
    <div>
        {/* Otros ítems del menú si son necesarios */}
    </div>
);

export default function DeskPagePrivate() {
    const [open, setOpen] = useState(false);
    const [formType, setFormType] = useState(null);
    const [user, setUser] = useState(null);
    const { t } = useTranslation();
    const isLargeScreen = useMediaQuery('(min-width:1200px)');
    const isSmallScreen = useMediaQuery('(max-width:1199px)');
    const [modalOpen, setModalOpen] = useState(false);
    const { isProductSelected, hasActiveProducts } = useProduct();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (isLargeScreen) {
            setOpen(true);
        } else if (isSmallScreen) {
            setOpen(false);
        }
    }, [isLargeScreen, isSmallScreen]);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await axios.get('/api/current_user');
                setUser(response.data);
            } catch (error) {
                console.error('Error fetching user:', error);
            }
        };
        fetchUser();
    }, []);

    const handleDrawerToggle = (e) => {
        e.stopPropagation();
        setOpen(!open);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const showSnackbar = () => {
        setSnackbarOpen(true);
    };

    const handleSignOut = async () => {
        try {
            await axios.post('/api/signout');
            navigate('/SignIn');
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    const handleMenuItemClick = () => {
        if (isSmallScreen) {
            setOpen(false);
        }
    };

    const drawerWidth = 250;
    const collapsedDrawerWidth = 56;

    let FormComponent;
    switch (formType) {
        case 'ProductForm':
            FormComponent = ProductForm;
            break;
        case 'EpicManager':
            FormComponent = EpicManager;
            break;
        case 'StoryManager':
            FormComponent = StoryManager;
            break;
        case 'CriteriaManager':
            FormComponent = CriteriaManager;
            break;
        default:
            FormComponent = null;
            break;
    }

    return (
        <Box sx={{ display: 'flex', minHeight: '100vh' }}>
            <CssBaseline />
            <Drawer
                variant="permanent"
                style={{
                    width: open ? (isSmallScreen ? '100vw' : drawerWidth) : collapsedDrawerWidth,
                    transition: 'width 0.5s',
                }}
                sx={{
                    '& .MuiDrawer-paper': {
                        position: 'relative',
                        whiteSpace: 'nowrap',
                        width: open ? (isSmallScreen ? '100vw' : drawerWidth) : collapsedDrawerWidth,
                        transition: 'width .5s',
                        boxSizing: 'border-box',
                        overflowX: 'hidden',
                    },
                }}
                open={open}
            >
                <Toolbar sx={{
                    display: 'flex',
                    justifyContent: open ? 'flex-end' : 'center',
                    paddingRight: open ? '8px' : '0',
                }}>
                    <IconButton onClick={handleDrawerToggle}>
                        {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </Toolbar>
                <Divider />
                <List>
                    {mainListItems(setFormType, t, open, setModalOpen, isProductSelected, hasActiveProducts, showSnackbar, handleMenuItemClick, isSmallScreen)}
                </List>
                {/*                 <Divider />
                <List>
                    <ListItem button onClick={handleSignOut}>
                        <ListItemIcon>
                            <ExitToAppIcon />
                        </ListItemIcon>
                        <ListItemText primary={t('signOut')} />
                    </ListItem>
                </List> */}
            </Drawer>
            {(!isSmallScreen || !open) && (
                <main style={{
                    flexGrow: 1,
                    padding: isSmallScreen ? 0 : '24px',
                    width: `calc(100% - ${open ? (isSmallScreen ? '100vw' : drawerWidth) : collapsedDrawerWidth}px)`,
                    transition: 'width 0.5s',
                    backgroundColor: '#f0f0f0',
                    minHeight: '100vh',
                }}>
                    <Container maxWidth="lg" sx={{ mt: 0, mb: 4, padding: isSmallScreen ? 0 : 0 }}>
                        <Grid container spacing={isSmallScreen ? 0 : 3} justifyContent="center" alignItems="center" style={{ minHeight: '100%' }}>
                            <Grid item xs={12} md={8} lg={8}>
                                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', backgroundColor: '#ffffff', width: '100%', margin: 'auto' }}>
                                    {FormComponent ? (
                                        <>
                                            <FormComponent />
                                        </>
                                    ) : (
                                        <>
                                            <WelcomeCard setFormType={setFormType} />
                                        </>
                                    )}
                                </Paper>
                            </Grid>
                        </Grid>
                    </Container>
                </main>
            )}
            <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                aria-labelledby="product-selector-modal-title"
                aria-describedby="product-selector-modal-description"
            >
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 300, bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24, p: 4 }}>
                    <Typography id="product-selector-modal-title" variant="h6" component="h2">
                        {t('selectProductLabel')}
                    </Typography>
                    <ProductSelectorWrapper isDrawerOpen={true} />
                </Box>
            </Modal>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                message={t('selectProductSnackbar')}
            />
        </Box>
    );
}
