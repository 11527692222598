import React, { useState, useEffect } from 'react';
import { TextField, Button, MenuItem, Grid, Typography, Container, Paper, CircularProgress, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import CustomSnackbar from '../messages/CustomSnackbar';
import StoryCard from './StoryCard';
import '../../css/StoryCard.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useProduct } from '../../context/ProductContext';

const GenerateStory = () => {
    const { selectedProduct } = useProduct();
    const [epic, setEpic] = useState('');
    const [epics, setEpics] = useState([]);
    const [context, setContext] = useState('');
    const [functionality, setFunctionality] = useState('');
    const [originalStory, setOriginalStory] = useState('');
    const [decomposedStories, setDecomposedStories] = useState([]);
    const [additionalInfo, setAdditionalInfo] = useState('');
    const [isGeneratingStory, setIsGeneratingStory] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isDecomposing, setIsDecomposing] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showDecomposition, setShowDecomposition] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarVariant, setSnackbarVariant] = useState('success');
    const { t } = useTranslation();

    useEffect(() => {
        const fetchProductData = async () => {
            if (selectedProduct) {
                try {
                    const [epicsResponse, productContextResponse] = await Promise.all([
                        axios.get(`/api/epics/product/${selectedProduct}`),
                        axios.get(`/api/products/${selectedProduct}`)
                    ]);

                    setEpics(epicsResponse.data);
                    setContext(productContextResponse.data.description);
                } catch (error) {
                    console.error('Error fetching product data:', error);
                }
            }
        };

        fetchProductData();
    }, [selectedProduct]);

    const extractTitleFromStory = (storyText) => {
        const titleMatch = storyText.match(/(?:Title|Título): (.*?)(?=\n|$)/);
        return titleMatch ? titleMatch[1].trim() : 'User Story';
    };

    const extractDescriptionFromStory = (storyText) => {
        return storyText.replace(/(?:Title|Título): .*?(?=\n|$)\n?/, '').trim();
    };

    const formatGeneratedStory = (storyText) => {
        let cleanText = storyText.replace(/[*]/g, '').trim();
        if (!cleanText.startsWith('Title:') && !cleanText.startsWith('Título:')) {
            cleanText = cleanText.replace(/^/, 'Title: ').replace(/\n/, '\n\n');
        }
        return cleanText;
    };

    const handleGenerateStories = async (e) => {
        e.preventDefault();
        setIsGeneratingStory(true);
        setAdditionalInfo('');
        setDecomposedStories([]);


        try {
            const contextResponse = await axios.get(`/api/epics/product/${selectedProduct}/context`);
            const prompt = t('promptTemplate', { context: contextResponse.data.context, functionality });
            const response = await axios.post('/generate-text', { prompt });
            const data = await sendPromptToServer(prompt, 'en', false);
            const formattedStory = formatGeneratedStory(data.generatedText);
            setOriginalStory(formattedStory);
        } catch (error) {
            console.error("Error generating user story:", error);
            setErrorMessage(`${t('errorGeneratingStory')} ${error.message}`);
        } finally {
            setIsGeneratingStory(false);
        }
    };

    async function sendPromptToServer(prompt, type) {
        const body = JSON.stringify({ prompt, type });
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/generate-text`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: body,
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    }

    const handleGenerateAcceptanceCriteria = async (story) => {
        setIsLoading(true);
        const prompt = `Context: ${context}\nOriginal Story: ${originalStory}\nUser Story: ${story}\n\n${t('promptAcceptanceCriteria')}`;
        try {
            const data = await sendPromptToServer(prompt, 'criteria');
            setIsLoading(false);
            return data.generatedText;
        } catch (error) {
            console.error("Error generating acceptance criteria:", error);
            setErrorMessage(`${t('errorGeneratingCriteria')} ${error.message}`);
            setIsLoading(false);
            return '';
        }
    };

    const decomposeStory = async () => {
        setIsDecomposing(true);
        const fullContext = `${context}\n\n${originalStory}\n\n${additionalInfo}`;
        const prompt = t('promptDecomposeTemplate', { fullContext, functionality, originalStory, additionalInfo });
        try {
            const data = await sendPromptToServer(prompt, 'en', true);
            if (data && data.generatedText) {
                const storyParts = data.generatedText.split('\n\n').map(part => part.trim()).filter(part => part.length > 0);
                const decomposedStories = storyParts.map((storyText) => {
                    const formattedStory = formatGeneratedStory(storyText);
                    const title = extractTitleFromStory(formattedStory);
                    const description = extractDescriptionFromStory(formattedStory);
                    return {
                        title: title.trim(),
                        description: description.trim()
                    };
                });
                setDecomposedStories(decomposedStories);
            } else {
                throw new Error(t('errorNoGeneratedText'));
            }
        } catch (error) {
            console.error("Error decomposing user story:", error);
            setErrorMessage(`${t('errorDecomposingStory')} ${error.message}`);
        } finally {
            setIsDecomposing(false);
        }
    };

    const handleCopyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
    };

    const handleSaveStory = async (story) => {
        try {
            await axios.post('/api/stories', {
                name: story.title,
                description: story.description,
                epic_id: epic
            });
            setSnackbarMessage(t('storySavedSuccess'));
            setSnackbarVariant('success');
        } catch (error) {
            console.error('Error saving story:', error);
            setSnackbarMessage(t('storySaveError'));
            setSnackbarVariant('error');
        } finally {
            setSnackbarOpen(true);
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <Container maxWidth="md">
            <Paper elevation={3} style={{ padding: '32px 16px' }}>
                <Typography variant="h6" gutterBottom>
                    {t('generateStoryTitle')}
                </Typography>
                <Grid container spacing={2}>
                    {/*                     <Grid item xs={12}>
                        <Typography variant="body1" gutterBottom>
                            {t('selectedProduct')}: {selectedProduct ? selectedProduct : t('noProductSelected')}
                        </Typography>
                    </Grid> */}
                    <Grid item xs={12}>
                        <TextField
                            select
                            label={t('selectEpicLabel')}
                            variant="outlined"
                            fullWidth
                            value={epic}
                            onChange={(e) => setEpic(e.target.value)}
                            disabled={!selectedProduct}
                        >
                            {epics.map(e => (
                                <MenuItem key={e.epic_id} value={e.epic_id}>
                                    {e.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label={t('functionalityLabel')}
                            variant="outlined"
                            fullWidth
                            value={functionality}
                            onChange={(e) => setFunctionality(e.target.value)}
                            id="functionality"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={handleGenerateStories}
                            disabled={!epic || isGeneratingStory}
                        >
                            {isGeneratingStory ? <CircularProgress size={24} /> : t('generateStoriesButton')}
                        </Button>
                    </Grid>
                </Grid>
                {errorMessage && <Typography color="error" sx={{ mt: 2 }}>{errorMessage}</Typography>}
                {originalStory && (
                    <StoryCard
                        story={{ title: extractTitleFromStory(originalStory), description: extractDescriptionFromStory(originalStory) }}
                        onCopy={handleCopyToClipboard}
                        onGenerateAcceptanceCriteria={handleGenerateAcceptanceCriteria}
                        onSave={handleSaveStory}
                    />
                )}
                <Box sx={{ mt: 2, mb: 2 }}>
                    <Box sx={{ cursor: 'pointer' }} onClick={() => setShowDecomposition(!showDecomposition)}>
                        <Typography variant="h6" className="decomposeStoriesButton" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            {t('decomposeButton')}
                            {showDecomposition ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </Typography>
                    </Box>
                    {showDecomposition && (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    label={t('additionalInfoPlaceholder')}
                                    required
                                    fullWidth
                                    id="additionalInfo"
                                    name="additionalInfo"
                                    multiline
                                    rows={3}
                                    variant="outlined"
                                    value={additionalInfo}
                                    onChange={(e) => setAdditionalInfo(e.target.value)}
                                    sx={{ mt: 2 }}  // Agregar margen superior
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    onClick={decomposeStory}
                                    disabled={isDecomposing}
                                >
                                    {isDecomposing ? <CircularProgress size={24} /> : t('generateUserStories')}
                                </Button>
                            </Grid>
                            {decomposedStories.map((story, index) => (
                                <Grid item xs={12} key={index}>
                                    <StoryCard
                                        story={story}
                                        onCopy={handleCopyToClipboard}
                                        onGenerateAcceptanceCriteria={handleGenerateAcceptanceCriteria}
                                        onSave={handleSaveStory}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    )}
                </Box>
            </Paper>
            <CustomSnackbar
                open={snackbarOpen}
                message={snackbarMessage}
                onClose={handleSnackbarClose}
                variant={snackbarVariant}
            />
        </Container>
    );
};

export default GenerateStory;
