import React, { useState } from 'react';
import { TextField, Button, Grid, Typography, Container, Paper, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import CustomSnackbar from '../messages/CustomSnackbar';
import { useProduct } from '../../context/ProductContext';

const AddEpic = () => {
    const { selectedProduct } = useProduct();
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarVariant, setSnackbarVariant] = useState('success');
    const { t } = useTranslation();

    const handleSaveEpic = async () => {
        setIsSaving(true);
        setSnackbarMessage('');
        try {
            await axios.post('/api/epics', {
                name: title,
                description,
                product_id: selectedProduct
            });
            setTitle('');
            setDescription('');
            setSnackbarMessage(t('epicSavedSuccess')); // Mensaje de confirmación
            setSnackbarVariant('success');
        } catch (error) {
            console.error('Error saving epic:', error);
            setSnackbarMessage(t('epicSaveError')); // Mensaje de error
            setSnackbarVariant('error');
        } finally {
            setIsSaving(false);
            setSnackbarOpen(true);
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <Container maxWidth="sm">
            <Paper elevation={3} style={{ padding: '32px 16px' }}>
                <Typography variant="h6" gutterBottom>
                    {t('addEpicTitle')}
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {/* El selector de producto se elimina aquí */}
                        {/* <Typography variant="body1" gutterBottom>
                            {t('selectedProduct')}: {selectedProduct ? selectedProduct : t('noProductSelected')}
                        </Typography> */}
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label={t('epicTitleLabel')}
                            variant="outlined"
                            fullWidth
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            id="epicTitle"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label={t('epicDescriptionLabel')}
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={4}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            id="epicDescription"
                        />
                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', gap: '8px' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSaveEpic}
                            disabled={isSaving || !selectedProduct}
                        >
                            {isSaving ? <CircularProgress size={24} /> : t('saveEpic')}
                        </Button>
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() => {
                                setTitle('');
                                setDescription('');
                            }}
                        >
                            {t('cancelEpic')}
                        </Button>
                    </Grid>
                </Grid>
                <CustomSnackbar
                    open={snackbarOpen}
                    message={snackbarMessage}
                    onClose={handleSnackbarClose}
                    variant={snackbarVariant}
                />
            </Paper>
        </Container>
    );
};

export default AddEpic;
