// Funciona
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Drawer, List, CssBaseline, Toolbar, Typography, IconButton,
    Container, Grid, Paper, ListItemIcon, ListItem, ListItemText, Divider, Box, useMediaQuery
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import HomeIcon from '@mui/icons-material/Home';
import LayersIcon from '@mui/icons-material/Layers';
import DescriptionIcon from '@mui/icons-material/Description';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import StoryForm from '../components/StoryForm';
import EpicForm from '../components/EpicForm';
import AcceptanceCriteriaForm from '../components/AcceptanceCriteriaForm';
import WelcomeCard from '../components/WelcomeCard';
import '../css/DeskPage.css';

const mainListItems = (setFormType, t) => (
    <div>
        <ListItem onClick={() => setFormType('WelcomeCard')} component="div" className="list-item">
            <ListItemIcon>
                <HomeIcon />
            </ListItemIcon>
            <ListItemText primary='General' />
        </ListItem>
        <ListItem onClick={() => setFormType('EpicForm')} component="div" className="list-item">
            <ListItemIcon>
                <LayersIcon />
            </ListItemIcon>
            <ListItemText primary={t('menuButtonEpics')} />
        </ListItem>
        <ListItem onClick={() => setFormType('StoryForm')} component="div" className="list-item">
            <ListItemIcon>
                <DescriptionIcon />
            </ListItemIcon>
            <ListItemText primary={t('menuButtonUserStories')} />
        </ListItem>
        <ListItem onClick={() => setFormType('AcceptanceCriteriaForm')} component="div" className="list-item">
            <ListItemIcon>
                <CheckCircleIcon />
            </ListItemIcon>
            <ListItemText primary={t('menuButtonCriteria')} className="list-item-text" />
        </ListItem>
    </div>
);

const secondaryListItems = (
    <div>
        {/* Otros ítems del menú si son necesarios */}
    </div>
);

export default function Dashboard() {
    const [open, setOpen] = useState(false);
    const [formType, setFormType] = useState(null);
    const { t } = useTranslation(); // Mover la llamada a useTranslation dentro del componente

    const isLargeScreen = useMediaQuery('(min-width:1200px)'); // Ajusta el valor según necesites
    const isSmallScreen = useMediaQuery('(max-width:1199px)'); // Ajusta el valor según necesites

    useEffect(() => {
        if (isLargeScreen) {
            setOpen(true);
        } else if (isSmallScreen) {
            setOpen(false);
        }
    }, [isLargeScreen, isSmallScreen]);

    const handleDrawerToggle = (e) => {
        e.stopPropagation(); // Detiene la propagación del evento clic
        setOpen(!open);
    };

    const drawerWidth = 250;
    const collapsedDrawerWidth = 56;

    let FormComponent;
    switch (formType) {
        case 'EpicForm':
            FormComponent = EpicForm;
            break;
        case 'StoryForm':
            FormComponent = StoryForm;
            break;
        case 'AcceptanceCriteriaForm':
            FormComponent = AcceptanceCriteriaForm;
            break;
        default:
            FormComponent = null;
            break;
    }

    return (
        <Box sx={{ display: 'flex', minHeight: '100vh' }}>
            <CssBaseline />
            <Drawer
                variant="permanent"
                style={{
                    width: open ? drawerWidth : collapsedDrawerWidth,
                    transition: 'width 0.5s',
                }}
                sx={{
                    '& .MuiDrawer-paper': {
                        position: 'relative',
                        whiteSpace: 'nowrap',
                        width: open ? drawerWidth : collapsedDrawerWidth,
                        transition: 'width .5s',
                        boxSizing: 'border-box',
                        overflowX: 'hidden', // Ocultar scrollbar horizontal
                    },
                }}
                open={open}
            >
                <Toolbar sx={{
                    display: 'flex',
                    justifyContent: open ? 'flex-end' : 'center', // Condiciona la alineación
                    paddingRight: open ? '8px' : '0', // Añade padding solo cuando está abierto
                }}>
                    <IconButton onClick={handleDrawerToggle}>
                        {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </Toolbar>
                <Divider />
                <List>{mainListItems(setFormType, t)}</List>
                <Divider />
                <List>{secondaryListItems}</List>
            </Drawer>
            <main style={{
                flexGrow: 1,
                padding: isSmallScreen ? 0 : '24px', // Sin padding en pantallas pequeñas
                width: `calc(100% - ${open ? drawerWidth : collapsedDrawerWidth}px)`,
                transition: 'width 0.5s',
                backgroundColor: '#f0f0f0',
                minHeight: '100vh', // Asegurar que cubra toda la altura de la pantalla
            }}>
                {/* <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}> */}
                {/* <Grid container spacing={3} justifyContent="center" alignItems="center" style={{ minHeight: '100%' }}> */}
                <Container maxWidth="lg" sx={{ mt: 0, mb: 4, padding: isSmallScreen ? 0 : 0 }}>
                    <Grid container spacing={isSmallScreen ? 0 : 3} justifyContent="center" alignItems="center" style={{ minHeight: '100%' }}>
                        <Grid item xs={12} md={8} lg={8}> {/* Ajusta los tamaños según necesites */}
                            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', backgroundColor: '#ffffff', width: '100%', margin: 'auto' }}>
                                {FormComponent ? <FormComponent /> : <WelcomeCard setFormType={setFormType} />}
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </main>
        </Box>
    );
}
