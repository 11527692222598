// PrivacyTerms.js
import React from 'react';

function PrivacyTermsPage() {
    return (
        <div>
            <h1>Privacy Terms</h1>
            <p>
                This web application collects only statistical data related to the use
                of the web app. The information is processed in accordance with OpenAI's
                API, which is not used by this company to train its models, as stated at
                <a href="https://openai.com/enterprise-privacy">OpenAI Enterprise Privacy</a>.
            </p>
        </div>
    );
}

export default PrivacyTermsPage;
