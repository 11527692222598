import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Footer from '../components/Footer';
import StoryCard from '../components/StoryCard';
import '../css/customMaterial.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

function FormPage() {
    const [context, setContext] = useState('');
    const [functionality, setFunctionality] = useState('');
    const [originalStory, setOriginalStory] = useState('');
    const [decomposedStories, setDecomposedStories] = useState([]);
    const [additionalInfo, setAdditionalInfo] = useState('');
    const [isGeneratingStory, setIsGeneratingStory] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isDecomposing, setIsDecomposing] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showDecomposition, setShowDecomposition] = useState(false);

    const { t } = useTranslation();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsGeneratingStory(true);
        setAdditionalInfo('');
        setDecomposedStories([]);
        const prompt = t('promptTemplate', { context, functionality });
        try {
            const data = await sendPromptToServer(prompt, 'en', false);
            setOriginalStory(data.generatedText);
        } catch (error) {
            console.error("Error generating user story:", error);
            setErrorMessage(`${t('errorGeneratingStory')} ${error.message}`);
        } finally {
            setIsGeneratingStory(false);
        }
    };

    async function sendPromptToServer(prompt, type) {
        const body = JSON.stringify({ prompt, type }); // Incluye el tipo en el cuerpo de la solicitud
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/generate-text`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: body,
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    }

    const handleGenerateAcceptanceCriteria = async (story) => {
        setIsLoading(true);
        const prompt = `Context: ${context}\nOriginal Story: ${originalStory}\nUser Story: ${story}\n\n${t('promptAcceptanceCriteria')}`;
        try {
            const data = await sendPromptToServer(prompt, 'criteria');  // Asegúrate de que 'criteria' esté manejado en el servidor
            setIsLoading(false);
            return data.generatedText;  // Devuelve el texto generado para los criterios de aceptación
        } catch (error) {
            console.error("Error generating acceptance criteria:", error);
            setErrorMessage(`${t('errorGeneratingCriteria')} ${error.message}`);
            setIsLoading(false);
            return '';  // Devuelve una cadena vacía en caso de error
        }
    };

    const decomposeStory = async () => {
        setIsDecomposing(true);
        const fullContext = `${context}\n\n${originalStory}\n\n${additionalInfo}`;
        const prompt = t('promptDecomposeTemplate', { fullContext, functionality, originalStory, additionalInfo });
        try {
            const data = await sendPromptToServer(prompt, 'en', true);
            if (data && data.generatedText) {
                const storyParts = data.generatedText.split('\n\n').map(part => part.trim()).filter(part => part.length > 0);
                setDecomposedStories(storyParts);
            } else {
                throw new Error(t('errorNoGeneratedText')); // Asegúrate de tener esta traducción en tu archivo i18n
            }
        } catch (error) {
            console.error("Error decomposing user story:", error);
            setErrorMessage(`${t('errorDecomposingStory')} ${error.message}`);
        } finally {
            setIsDecomposing(false);
        }
    };

    const handleCopyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Container component="main" maxWidth="md" sx={{ mt: 4 }}>

                {/* Content Section */}
                <Box className="content-section">
                    <Typography variant="h6" className="section-title">
                        {t('homeTitleGenerateStory')}
                    </Typography>
                    <Typography variant="body1" className="home-section-description">
                        {t('homeDescriptionGenerateStory')}
                    </Typography>
                </Box>

                {/* Form Section */}
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <Typography variant="caption" className="form-caption caption-text" sx={{ display: 'block', mb: 1, color: 'text.secondary' }}>
                        {t('contextLabel')}
                    </Typography>
                    <TextField
                        className="custom-textfield multiline"
                        required
                        fullWidth
                        id="context"
                        name="context"
                        autoComplete="context"
                        autoFocus
                        multiline
                        rows={4}
                        value={context}
                        onChange={(e) => setContext(e.target.value)}
                        variant="outlined"
                    />
                    <Typography variant="caption" className="form-caption caption-text" sx={{ display: 'block', mb: 1, color: 'text.secondary' }}>
                        {t('functionalityLabel')}
                    </Typography>
                    <TextField
                        className="custom-textfield multiline"
                        required
                        fullWidth
                        id="functionality"
                        name="functionality"
                        autoComplete="functionality"
                        value={functionality}
                        onChange={(e) => setFunctionality(e.target.value)}
                        variant="outlined"
                    />
                    <Button
                        className="custom-button"
                        type="submit"
                        disabled={isGeneratingStory}
                        onClick={handleSubmit}
                    >
                        {isGeneratingStory ? <CircularProgress size={24} /> : t('generateButton')}
                    </Button>
                </Box>

                {/* Error Message Display */}
                {errorMessage && <Typography color="error" sx={{ mt: 2 }}>{errorMessage}</Typography>}

                {originalStory && (
                    <StoryCard
                        story={originalStory}
                        onCopy={handleCopyToClipboard}
                        onGenerateAcceptanceCriteria={handleGenerateAcceptanceCriteria} // Asegúrate de pasar esto
                    />
                )}

                <Box sx={{ mt: 2, mb: 2 }}>
                    {/* Decomposition Toggle and Section */}
                    <Box sx={{ cursor: 'pointer' }} className="decompose-toggle" onClick={() => setShowDecomposition(!showDecomposition)}>
                        <Typography variant="h6" className="decompose-title" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            {t('decomposeButton')}
                            {showDecomposition ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </Typography>
                    </Box>

                    {/*                     <Box className="decompose-toggle" onClick={() => setShowDecomposition(!showDecomposition)}>
                        <Typography variant="h6" className="decompose decompose-title">
                            {t('decomposeButton')}
                            {showDecomposition ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </Typography>
                    </Box> */}

                    {showDecomposition && (
                        <>
                            <Typography variant="caption" className="form-caption caption-text" sx={{ display: 'block', mb: 1, color: 'text.secondary' }}>
                                {t('additionalInfoLabel', { defaultValue: t('additionalInfoPlaceholder') })}
                            </Typography>
                            <TextField
                                className="custom-textfield"
                                required
                                fullWidth
                                id="additionalInfo"
                                name="additionalInfo"
                                multiline
                                rows={3}
                                variant="outlined"
                                value={additionalInfo}
                                onChange={(e) => setAdditionalInfo(e.target.value)}
                            />

                            <Button
                                className="custom-button"
                                onClick={decomposeStory}
                                disabled={isDecomposing}
                            >
                                {isDecomposing ? <CircularProgress size={24} /> : t('generateUserStories')}
                            </Button>

                            {decomposedStories.map((story, index) => (
                                <StoryCard
                                    key={index}
                                    story={story}
                                    onCopy={handleCopyToClipboard}
                                    onGenerateAcceptanceCriteria={handleGenerateAcceptanceCriteria} // También aquí
                                />
                            ))}
                        </>
                    )}
                </Box>
            </Container>
            <Footer />
        </Box>
    );
}

export default FormPage;
